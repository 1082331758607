import { CognitoAuth } from "amazon-cognito-auth-js";
import getAuthConfig from "auth/AuthConfigurations";

// Cognito response has a grant code with the format `..?code=...`
const COGNITO_GRANT_CODE_KEY = "?code";

export function getAuth() {
    const authConfig = getAuthConfig();
    const auth = new CognitoAuth(authConfig);
    // state is saved as a random number as part of the state value of cognito
    // it will be returned as part of the redirect_uri
    const nonce = auth.generateRandomString(
        auth.getCognitoConstants().STATELENGTH, auth.getCognitoConstants().STATEORIGINSTRING
    );

    // Adding the current window's pathname to state as well
    // we can use this to restore our location when session expires
    // It is important that we add the search parameters if they exist otherwise the first
    // time a person navigates to a search page the search terms will be removed and it is
    // is a bad user experience. See https://sim.amazon.com/issues/V1004964473 for more details
    const { pathname, search } = window.location;
    const redirectPathWithSearch = search ? pathname + search : pathname;
    const path = encodeURIComponent(redirectPathWithSearch);
    const state = `${nonce}_${path}`;
    auth.setState(state);

    // Make sure you turn on OAuth2 Authorization Code Grant flow
    auth.useCodeGrantFlow();
    return auth;
}

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
// the SDK will report weird messages because of using old grant code.
function removeQueryFromLocation(success) {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    const state = window.location.href.split("&state=")[1];
    if (success) {
        const savedPath = state?.split("_")[1];
        if (savedPath) {
            window.location.href = decodeURIComponent(savedPath);
        }
        return;
    }

    const newHref = window.location.href.split("?")[0];
    window.history.replaceState(undefined, "Fremont Website", newHref);
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export default function ensureAuthenticated() {
    return new Promise((resolve, reject) => {
        const auth = getAuth();

        // Register callback functions
        auth.userhandler = {
            onFailure: (err) => {
                removeQueryFromLocation(false);
                reject(err);
            },
            onSuccess: (session) => {
                // On success the session value contains the original state assigned
                // This state is returned as part of the redirect_uri (i.e. the current window)
                // If a hacker has hijacked the redirect, then they will return incorrect state which is saved
                // in our session variable
                // https://auth0.com/docs/protocols/oauth2/oauth-state
                if (session.state && session.state === window.location.href.split("&state=")[1]) {
                    removeQueryFromLocation(true);
                    resolve(auth);
                } else {
                    reject(session);
                }
            }
        };

        // eslint-disable-next-line prefer-destructuring
        const href = window.location.href;
        let session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(auth);
            removeQueryFromLocation(true);
        } else if (href.indexOf(COGNITO_GRANT_CODE_KEY) > 0) {
            // This is required because Cognito needs to get the authentication result from the query string
            // The parsing is done asynchronously, and the result will be passed to the userHandler.
            // Once the result is parsed, onSuccess userhandler will resolve the promise.
            auth.parseCognitoWebResponse(href);
        } else {
            // Cognito SDK will handle session refresh / authentication.
            auth.clearCachedTokensScopes();
            session = auth.getSession();
        }
    });
}