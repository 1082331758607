export default class DevEnvironment {
    static YOUR_USER_POOL_ID = "us-west-2_g16MsGr6u";

    static YOUR_CLIENT_ID = "7gm625fkthbbi72q9i762vdc58";

    static YOUR_ALIAS = "sydneyrl";

    static APP_WEB_DOMAIN = `fremont-${DevEnvironment.YOUR_ALIAS}-test-userpool.auth.us-west-2.amazoncognito.com`;

    static UI_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.ui.fremont.networking.aws.a2z.com`;

    static OPERATIONS_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.operations.fremont.networking.aws.a2z.com`;
}
