import React, { useEffect, useState } from "react";
import { Box, Grid, Header, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { LightHouseButton, LightHouseInput, LightHouseSelect } from "../components/CommonComponents";
import Constants from "../utils/Constants";
import HelperFunctions from "../../../common/HelperFunctions";
import MangoHelperFunctions from "../../../mango/js/common/HelperFunctions";
import { LinkServiceExpandableSection } from "../../../mango/js/components/CommonComponents";
import OspDashboardHelper from "./OspDashboardHelper";

function SearchInformation(props) {
    /**
     * This function handles changes to the search parameters
     */
    function handleSearchParamChange(evt, searchParam, setSearchParam) {
        const updatedSearchParameters = HelperFunctions.deepClone(searchParam);
        updatedSearchParameters.map((searchParameterObject) => {
            if (searchParameterObject.id === evt.target.id) {
                if (evt.detail !== undefined && evt.detail.value !== undefined) {
                    return Object.assign(searchParameterObject, { parameterValue: evt.detail.value });
                }
                return Object.assign(searchParameterObject, { parameterName: evt.selectedOption });
            }
            return searchParameterObject;
        });
        setSearchParam(updatedSearchParameters);
    }

    /**
     * This function removes a search param
     */
    function removeSearchParam(evt, searchParam, setSearchParam) {
        const updatedSearchParameters = HelperFunctions.subtractSpecificObjectHelper(
            searchParam,
            evt.target.id,
            Constants.INPUT_TYPES.searchParams
        );
        setSearchParam(updatedSearchParameters);
    }

    /**
     * This function add a new filter to the search params area
     */
    function addSearchParam(evt, searchParam, setSearchParam) {
        const newSearchParamId = `searchParam${searchParam.length + 1}`;
        const updatedSearchParameters = props.searchParameters.concat({
            id: newSearchParamId,
            parameterName: {},
            parameterValue: "",
            errorText: ""
        });
        setSearchParam(updatedSearchParameters);
    }

    function getOptions(options, alreadySelectedOptions) {
        return options.filter(currentOption =>
            !alreadySelectedOptions.map(alreadySelectedOption =>
                alreadySelectedOption.parameterName.id).includes(currentOption.id));
    }

    function isAddFilterDisabled(options, searchParams) {
        return (Object.keys(options) <= Object.keys(searchParams)) || props.isSearching;
    }

    function getSearchParams(searchParams) {
        const searchParamsToReturn = {};
        searchParams.forEach((searchParam) => {
            if (Object.keys(searchParam.parameterName).length > 0 && searchParam.parameterValue) {
                searchParamsToReturn[searchParam.parameterName.id] =
                    { value: searchParam.parameterValue, styxName: searchParam.parameterName.styxName };
            }
        });
        return searchParamsToReturn;
    }

    function isSearchButtonDisabled(searchParams) {
        if (!searchParams || searchParams.length === 0) {
            return true;
        }
        return !searchParams.some(searchParam =>
            searchParam.parameterValue.length > 0);
    }


    return (
        <Grid
            gridDefinition={
                [{ colspan: { default: 7 } }, { colspan: { default: 7 } }]
            }
        >
            <SpaceBetween direction="vertical" size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}>
                {
                    props.searchParameters.map(searchParameter => (
                        <Grid
                            gridDefinition={
                                [{ colspan: { default: 5 } }, { colspan: { default: 5 } }, { colspan: { default: 2 } }]
                            }
                        >
                            <div>
                                <LightHouseSelect
                                    id={searchParameter.id}
                                    disabled={searchParameter.isDisabled}
                                    placeHolder="Select a search field"
                                    options={
                                        getOptions(Constants.OSP_PANEL_LOCATION_PARAMETER_OPTIONS,
                                            props.searchParameters)}
                                    selectedOption={Object.keys(searchParameter.parameterName).length === 0
                                        ? "" : searchParameter.parameterName}
                                    onChange={(evt) => {
                                        handleSearchParamChange(evt, props.searchParameters, props.setSearchParameters);
                                    }}
                                />
                            </div>
                            <div>
                                <LightHouseInput
                                    id={searchParameter.id}
                                    placeholder="Enter Value"
                                    value={searchParameter.parameterValue}
                                    onChange={(evt) => {
                                        handleSearchParamChange(evt, props.searchParameters, props.setSearchParameters);
                                    }}
                                />
                            </div>
                            <div>
                                <LightHouseButton
                                    id={searchParameter.id}
                                    disabled={searchParameter.isDisabled}
                                    variant="normal"
                                    iconName="close"
                                    onClick={(evt) => {
                                        removeSearchParam(evt, props.searchParameters, props.setSearchParameters);
                                    }}
                                />
                            </div>
                        </Grid>
                    ))
                }
                <Grid
                    gridDefinition={
                        [{ colspan: { default: 5 } }, { colspan: { default: 5 } }]
                    }
                >
                    <Box float="left">
                        <LightHouseButton
                            variant="primary"
                            iconName="add-plus"
                            disabled={isAddFilterDisabled(Constants.OSP_PANEL_LOCATION_PARAMETER_OPTIONS,
                                props.searchParameters)}
                            onClick={evt =>
                                addSearchParam(evt, props.searchParameters, props.setSearchParameters)}
                        >Add Filter
                        </LightHouseButton>
                    </Box>

                    <Box float="right">
                        <LightHouseButton
                            variant="primary"
                            iconName="search"
                            loading={props.isSearching}
                            disabled={isSearchButtonDisabled(props.searchParameters)}
                            onClick={() => props.getQueriedResults(getSearchParams(props.searchParameters))}
                        >Start Search
                        </LightHouseButton>
                    </Box>
                </Grid>
            </SpaceBetween>
        </Grid>
    );
}


export default function OspPanelLocationSearchHandler(props) {
    const [ospPanelLocationResults, setOspPanelLocationResults] = useState([]);
    const [panelLocationWithPortsToLinkMap, setPanelLocationWithPortsToLinkMap] = useState({});
    const [selectedOspLocation, setSelectedOspLocation] = useState({});
    const [selectedItems, setSelectedItems] = useState([{}]);
    const [isSearching, setIsSearching] = useState(false);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [isOspLocationsUpdated, setIsOspLocationsUpdated] = useState(false);

    function getOSPSitesFromCutsheetKeyValuePairs(cutsheetKeyValuePairs) {
        const firstRow = HelperFunctions.deepClone(cutsheetKeyValuePairs);
        const aOSPSite = MangoHelperFunctions.getSiteNameFromHostname(firstRow[Constants.ISP_ATTRIBUTES.a_hostname]);
        const zOSPSite = MangoHelperFunctions.getSiteNameFromHostname(firstRow[Constants.ISP_ATTRIBUTES.z_hostname]);
        return [aOSPSite, zOSPSite];
    }


    function generateSearchParameters(searchParamsArray) {
        const searchParameters = [];
        for (let i = 0; i < searchParamsArray.length; i += 1) {
            searchParameters.push({
                id: `searchParam${i}`,
                parameterName: {
                    label: searchParamsArray[i].label,
                    id: searchParamsArray[i].id,
                    styxName: searchParamsArray[i].styxName
                },
                parameterValue: "",
                errorText: "",
                isDisabled: searchParamsArray[i].disabled
            });
        }
        return searchParameters;
    }

    const [searchParameters, setSearchParameters] =
        useState(generateSearchParameters(Constants.OSP_PANEL_LOCATION_PARAMETER_OPTIONS,
            props.updatedCutsheetKeyValuePairs));

    function updateSearchParametersWithOSPSites(updatedCutsheetKeyValuePairs) {
        let updatedSearchParams = HelperFunctions.deepClone(searchParameters);
        const [aOSPSite, zOSPSite] = getOSPSitesFromCutsheetKeyValuePairs(updatedCutsheetKeyValuePairs);
        if (aOSPSite && zOSPSite) {
            updatedSearchParams = updatedSearchParams.map((param) => {
                if (param.parameterName.id === "aSiteCode") {
                    return { ...param, parameterValue: aOSPSite };
                } else if (param.parameterName.id === "zSiteCode") {
                    return { ...param, parameterValue: zOSPSite };
                }
                return param;
            });

            setSearchParameters(updatedSearchParams);
            setIsOspLocationsUpdated(true);
        }
    }

    useEffect(() => {
        if (!isOspLocationsUpdated && props.updatedCutsheetKeyValuePairs !== undefined
            && props.updatedCutsheetKeyValuePairs.length > 0) {
            // We asssume that all the a/z location of r2r link will be same.
            updateSearchParametersWithOSPSites(props.updatedCutsheetKeyValuePairs[0]);
        }
    }, [props.updatedCutsheetKeyValuePairs]);

    async function getQueriedResults(searchQueryParams) {
        setIsSearching(true);
        setIsRowSelected(false);
        await props.getOSPPanelLocations(searchQueryParams,
            setOspPanelLocationResults, setPanelLocationWithPortsToLinkMap);
        setIsSearching(false);
        if (!isTableVisible) {
            setIsTableVisible(true);
        }
    }

    function onSelectionChange(detail) {
        setSelectedOspLocation(detail.selectedItems[0]);
        setSelectedItems(detail.selectedItems);
        setIsRowSelected(true);
    }


    return (
        <div>
            <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                <LinkServiceExpandableSection
                    variant="container"
                    defaultExpanded="true"
                    header={<Header variant="h2">OSP Panel Location Search</Header>}
                >
                    <SearchInformation
                        searchParameters={searchParameters}
                        setSearchParameters={setSearchParameters}
                        getQueriedResults={getQueriedResults}
                        isSearching={isSearching}
                    />
                </LinkServiceExpandableSection>
                {isTableVisible &&
                <LinkServiceExpandableSection
                    variant="container"
                    defaultExpanded="true"
                    header={<Header variant="h2">Found OSP Panel Locations</Header>}
                >
                    <Table
                        items={ospPanelLocationResults}
                        loading={isSearching}
                        visible={isTableVisible}
                        columnDefinitions={[
                            {
                                id: "aOspPanelLocation",
                                header: "A Panel Location",
                                cell: item => item.aOspPanelLocation
                            },
                            {
                                id: "aSideTotalPorts",
                                header: "A Total Available Ports",
                                cell: item => item.aAvailablePorts.length
                            },
                            {
                                id: "aSideAvailablePorts",
                                header: "A Available Ports",
                                cell: item => OspDashboardHelper.mergeSortedContinuousPorts(item.aAvailablePorts)
                            },
                            {
                                id: "zOspPanelLocation",
                                header: "Z Panel Location",
                                cell: item => item.zOspPanelLocation
                            },
                            {
                                id: "zSideTotalPorts",
                                header: "Z Total Available Ports",
                                cell: item => item.zAvailablePorts.length
                            },
                            {
                                id: "zSideAvailablePorts",
                                header: "Z Available Ports",
                                cell: item => OspDashboardHelper.mergeSortedContinuousPorts(item.zAvailablePorts)
                            }
                        ]}
                        selectionType="single"
                        onSelectionChange={({ detail }) => { onSelectionChange(detail); }}
                        selectedItems={selectedItems}
                    />
                    <Box padding={{ top: "xs", bottom: "xs" }} float="right">
                        <LightHouseButton
                            variant="primary"
                            iconName="copy"
                            disabled={!isRowSelected}
                            onClick={
                                () => {
                                    props.onCopyToSelectedRowsClick(selectedOspLocation,
                                        panelLocationWithPortsToLinkMap);
                                }
                            }
                        >Copy to selected rows
                        </LightHouseButton>
                    </Box>
                </LinkServiceExpandableSection>
                }
            </SpaceBetween>
        </div>

    );
}