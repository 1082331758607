import React from "react";
import {
    Switch,
    Redirect,
    Route,
    useLocation
} from "react-router-dom";
import { hot } from "react-hot-loader";
import AsnCreatePage from "asn/AsnCreatePage";
import AsnDetailsPage from "asn/AsnDetailsPage";
import CircuitDesignDetailsPage from "circuitDesign/CircuitDesignDetailsPage";
import Constants from "utils/Constants";
import ContactCreatePage from "contact/ContactCreatePage";
import ContactDetailsPage from "contact/ContactDetailsPage";
import DashboardPage from "dashboard/DashboardPage";
import OperationsPage from "operations/OperationsPage";
import FremontErrorPage from "common/FremontErrorPage";
import FremontFooter from "common/FremontFooter";
import HaloFooter from "common/HaloFooter";
import ManageOrderAssignment from "manager/ManageOrderAssignment";
import OrderCreatePage from "order/OrderCreatePage";
import OrderDetailsPage from "order/OrderDetailsPage";
import ProviderCreatePage from "provider/ProviderCreatePage";
import ProviderDetailsPage from "provider/ProviderDetailsPage";
import ProviderServiceCreatePage from "providerService/ProviderServiceCreatePage";
import ProviderServiceDetailsPage from "providerService/ProviderServiceDetailsPage";
import SearchPage from "common/SearchPage";
import SiteCreatePage from "site/SiteCreatePage";
import SiteDetailsPage from "site/SiteDetailsPage";
import StageSla from "stageSla/StageSla";
import { Box } from "@amzn/awsui-components-react/polaris";
import { ComponentConstants } from "utils/CommonComponents";
import HelperFunctions from "common/HelperFunctions";
import CutsheetTablePage from "../mango/js/cutsheet/CutsheetTablePage";
import ReportFiberPage from "../mango/js/report/ReportFiberPage";
import LinkSearchPage from "../mango/js/search/LinkSearchPage";
import LinkDetailPage from "../mango/js/link/LinkDetailPage";
import LinkServiceFooter from "../mango/js/common/LinkServiceFooter";
import LinkVisualizationPage from "../mango/js/visualization/LinkVisualizationPage";
import IspPatchPanelConfigDashboard from "../lighthouse/js/ispManagement/IspPatchPanelConfigDashboard";
import IspDashboard from "../lighthouse/js/ispDashboard/IspDashboard";
import LighthouseLandingPage from "./LighthouseLandingPage";
import HaloIFramePage from "./HaloIFramePage";
import HaloHeader from "./HaloHeader";
import LighthouseFooter from "./LighthouseFooter";
import IspDashboardV2 from "../lighthouse/js/ispDashboard/IspDashboardV2";

const generateRouteAndRedirect = (route) => {
    // From path is the current path minus the additions made as a part of launching the lighthouse UI
    const fromPath = HelperFunctions.constructLegacyPath(route.props.path);
    // React Redirect does not handle query parameters automatically. We have to explicitly include them
    // in the redirect component. If we do not do this, any redirect to a page that includes search in its URL
    // will lose the search information
    const pathname = route.props.path;
    // We need to explicitly define the search because we do not follow the search syntax enforced by
    // React per https://v5.reactrouter.com/web/api/Redirect and https://github.com/pillarjs/path-to-regexp/tree/v1.7.0
    // By setting the search as an explicit element, we avoid parsing errors thrown by react
    const { search } = window.location;
    return [
        <Redirect key={`redirect-${fromPath}`} from={fromPath} to={{ pathname, search }}/>,
        route
    ];
};

const getFooter = () => {
    if (Object.values(Constants.MANGO_ROUTES).some(route => useLocation().pathname.startsWith(route))) {
        return <LinkServiceFooter/>;
    } else if (useLocation().pathname.startsWith(Constants.LIGHTHOUSE_PREPENDED_PATHS.halo)) {
        return <HaloFooter/>;
    } else if (useLocation().pathname === Constants.LIGHTHOUSE_ROUTES.lighthouseLandingPage
        || useLocation().pathname === Constants.LIGHTHOUSE_ROUTES.reportFiber) {
        return <LighthouseFooter/>;
    }
    return <FremontFooter/>;
};

const FremontRouter = (props) => {
    // We disable the no-use-before-define lint error to keep this file more readable
    // eslint-disable-next-line no-use-before-define
    const routes = getRoutesThatNeedRedirects(props);
    const routesWithRedirects = [];
    routes.forEach(route => routesWithRedirects.push(...generateRouteAndRedirect(route)));
    // Add routes that don't need redirects
    // eslint-disable-next-line no-use-before-define
    const allRoutesAndRedirects = routesWithRedirects.concat(getRoutesThatDontNeedRedirects(props));

    return (
        <div className="awsui">
            <div className="content">
                <Switch>
                    {allRoutesAndRedirects}

                    {/* We still maintain the default error page for all components */}
                    {/* TODO rename this to lighthouse error page */}
                    <Route
                        render={({ history }) => (
                            <FremontErrorPage
                                auth={props.auth}
                                history={history}
                                sideNavError={props.sideNavError}
                                updateSearchResults={props.updateSearchResults}
                            />
                        )}
                    />
                </Switch>
            </div>
            <Box padding={{ top: ComponentConstants.BOX_PAGE_PADDING }}>
                {getFooter()}
            </Box>
        </div>
    );
};

const getRoutesThatDontNeedRedirects = (props) => {
    const routes = [
        // We have a dedicated route for the lighthouse landing page and the fremont dashboard. The reason
        // is that we do not want to perform any redirects here. The original fremont dashboard page was
        // the root of the website at "/" but that is being replaced with the Lighthouse landing page.
        // As such we do not want to redirect the customer but show them the landing page instead.
        <Route
            path={Constants.LIGHTHOUSE_ROUTES.lighthouseLandingPage}
            exact
            render={({ history }) => (
                <LighthouseLandingPage
                    auth={props.auth}
                    history={history}
                    sideNavError={props.sideNavError}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.fremontDashboard}
            exact
            render={({ history }) => (
                <DashboardPage
                    auth={props.auth}
                    user={props.user}
                    history={history}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    allProviderOptions={props.allProviders}
                    allProvidersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.MANGO_ROUTES.linkServiceDashboard}
            exact
            render={() => (
                <Redirect to={Constants.MANGO_ROUTES.linkSearch}/>
            )}
        />,
        // We have a dedicated route for the Halo page since we don't need a redirect for it
        <Route
            path={Constants.LIGHTHOUSE_PREPENDED_PATHS.halo}
            exact
            render={({ history }) => (
                <div>
                    <HaloHeader
                        history={history}
                        sideNavError={props.sideNavError}
                    />
                    <HaloIFramePage
                        auth={props.auth}
                        user={props.user}
                        history={history}
                        sideNavError={props.sideNavError}
                    />
                </div>
            )}
        />
    ];

    return routes;
};

const getRoutesThatNeedRedirects = (props) => {
    const routes = [
        <Route
            path={`${Constants.ROUTES.asn}/:asnId`}
            render={({ history, location, match }) => (
                <AsnDetailsPage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.createAsn}/:providerName`}
            render={({ history, location, match }) => (
                <AsnCreatePage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    isModal={false}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.createAsn}
            exact
            render={({ history }) => (
                <AsnCreatePage
                    history={history}
                    auth={props.auth}
                    isModal={false}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.contact}/:contactId`}
            render={({ history, location, match }) => (
                <ContactDetailsPage
                    key={match.params.contactId}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.createContact}/:providerName`}
            render={({ history, location, match }) => (
                <ContactCreatePage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.createContact}
            exact
            render={({ history }) => (
                <ContactCreatePage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.operations}
            exact
            render={({ history }) => (
                <OperationsPage
                    auth={props.auth}
                    user={props.user}
                    history={history}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.order}/:orderId`}
            render={({ history, location, match }) => (
                <OrderDetailsPage
                    key={match.params.orderId}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                    siteOptions={props.siteOptions}
                    siteOptionsLoading={props.siteOptionsLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.createOrder}/:providerName`}
            render={({ history, location, match }) => (
                <OrderCreatePage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    allProviders={props.allProviders}
                    allProvidersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.createOrder}
            exact
            render={({ history }) => (
                <OrderCreatePage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    allProviders={props.allProviders}
                    allProvidersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.provider}/:providerName`}
            render={({ history, location, match }) => (
                <ProviderDetailsPage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    allProviders={props.allProviders}
                    allProvidersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.createProvider}/:providerName`}
            render={({ history, location, match }) => (
                <ProviderCreatePage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.createProvider}
            exact
            render={({ history }) => (
                <ProviderCreatePage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.providerService}/:providerServiceId`}
            render={({ history, location, match }) => (
                <ProviderServiceDetailsPage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.createProviderService}/:providerName`}
            render={({ history, location, match }) => (
                <ProviderServiceCreatePage
                    key={match.params.providerName}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    isModal={false}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.createProviderService}
            exact
            render={({ history }) => (
                <ProviderServiceCreatePage
                    history={history}
                    auth={props.auth}
                    isModal={false}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.search}
            exact
            render={({ history }) => (
                <SearchPage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    searchResults={props.searchResults}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.site}/:siteId`}
            render={({ history, location, match }) => (
                <SiteDetailsPage
                    key={match.params.siteId}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    allProvidersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.createSite}/:providerName`}
            render={({ history, location, match }) => (
                <SiteCreatePage
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.createSite}
            exact
            render={({ history }) => (
                <SiteCreatePage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                    providerOptions={props.allProviders}
                    providersLoading={props.allProvidersLoading}
                />
            )}
        />,
        <Route
            path={`${Constants.ROUTES.circuitDesign}/:circuitDesignId`}
            render={({ history, location, match }) => (
                <CircuitDesignDetailsPage
                    key={match.params.circuitDesignId}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.manageOrderAssignment}
            exact
            render={({ history }) => (
                <ManageOrderAssignment
                    auth={props.auth}
                    user={props.user}
                    history={history}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.ROUTES.stageSla}
            exact
            render={({ history }) => (
                <StageSla
                    auth={props.auth}
                    user={props.user}
                    history={history}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.MANGO_ROUTES.cutsheetUpload}
            exact
            render={({ history }) => (
                <CutsheetTablePage
                    history={history}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.LIGHTHOUSE_ROUTES.ispPatchPanelManagement}
            exact
            render={({ history }) => (
                <IspPatchPanelConfigDashboard
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.LIGHTHOUSE_ROUTES.ispDashboard}
            exact
            render={({ history, location }) => {
                const searchParams = new URLSearchParams(location.search);
                const searchTerm = searchParams.get("searchTerm") || searchParams.get("SearchTerm");
                const cutsheetType = searchParams.get("cutsheetType") || searchParams.get("CutsheetType");
                return (
                    <IspDashboard
                        history={history}
                        auth={props.auth}
                        sideNavError={props.sideNavError}
                        updateSearchResults={props.updateSearchResults}
                        searchTerm={searchTerm}
                        cutsheetType={cutsheetType}
                    />
                );
            }}
        />,
        <Route
            path={Constants.LIGHTHOUSE_ROUTES.ispDashboardV2}
            exact
            render={({ history, location }) => {
                const searchParams = new URLSearchParams(location.search);
                const searchTerm = searchParams.get("searchTerm") || searchParams.get("SearchTerm");
                const cutsheetType = searchParams.get("cutsheetType") || searchParams.get("CutsheetType");
                return (
                    <IspDashboardV2
                        history={history}
                        auth={props.auth}
                        sideNavError={props.sideNavError}
                        updateSearchResults={props.updateSearchResults}
                        searchTerm={searchTerm}
                        cutsheetType={cutsheetType}
                    />
                );
            }}
        />,
        <Route
            path={`${Constants.MANGO_ROUTES.linkSearch}?:searchTerm`}
            exact
            render={({ history, location, match }) => (
                <LinkSearchPage
                    key={match.params.searchTerm}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.MANGO_ROUTES.linkSearch}
            exact
            render={({ history }) => (
                <LinkSearchPage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />,
        <Route
            path={Constants.LIGHTHOUSE_ROUTES.reportFiber}
            exact
            render={({ history }) => (
                <ReportFiberPage
                    history={history}
                    auth={props.auth}
                    sideNavError={props.sideNavError}
                />
            )}
        />,
        <Route
            path={`${Constants.MANGO_ROUTES.linkDetails}/:linkId`}
            exact
            render={({ history, location, match }) => (
                <LinkDetailPage
                    key={match.params.searchTerm}
                    history={history}
                    location={location}
                    match={match}
                    auth={props.auth}
                    user={props.user}
                    sideNavError={props.sideNavError}
                    updateSearchResults={props.updateSearchResults}
                />
            )}
        />
    ];

    if (!HelperFunctions.isProd() || HelperFunctions.userInPosixGroup(props.user,
        Constants.POSIX_GROUPS.NEST)) {
        routes.push(
            <Route
                path={Constants.MANGO_ROUTES.linkVisualization}
                exact
                render={({ history }) => (
                    <LinkVisualizationPage
                        history={history}
                        auth={props.auth}
                        sideNavError={props.sideNavError}
                        updateSearchResults={props.updateSearchResults}
                    />
                )}
            />
        );
    }

    return routes;
};

export default hot(module)(FremontRouter);