import React from "react";
import {
    Box,
    Icon,
    Link,
    Popover
} from "@amzn/awsui-components-react/polaris";
import { countries, currencies } from "country-data";
import {
    IconWithColoredText,
    optionalLabel,
    requiredLabel
} from "utils/CommonComponents";
import moment from "moment";
import Constants from "utils/Constants";
import FremontBackendClient from "common/FremontBackendClient";
import SiteValidation from "site/SiteValidation";
import DevEnvironment from "common/DevEnvironment";
import { isEqual, union, uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";

export default class HelperFunctions {
    static LIGHTHOUSE_ROOT_DOMAIN = "lighthouse.networking.aws.dev";
    static FREMONT_ROOT_DOMAIN = "fremont.networking.aws.a2z.com";

    /**
    * Accepts an Array of promises and guarantees that all will be tried.
    * Returns back a single promise containing an array of result.
    * It does not convert non-promise to a promise so please do not pass
    * anything other than list of promises in the `promises`
    * Promise.allSettled is not supported in Firefox 68.6.1esr.
    * Refer to MDN https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
    *
    * @promises { [Promise] } A list of Promise objects.
    * @returns [{ status: 'fulfilled' || 'rejected' , value || reason }]
    */
    static allSettled = promises => Promise.all(
        promises.map(prom => prom
            .then(value => ({
                status: Constants.PROMISE_FULFILLED, value
            }))
            .catch(reason => ({
                status: Constants.PROMISE_REJECTED, reason
            })))
    );

    /*
     * This function displays an error message on the flashbar pertaining to what happened if the server call
     * returned an error, and handles setting additional states.
     * NOTE: there is a "flashbar" state on every major page of the app that this function interacts with.
     */
    static displayFlashbarError = (reactComponent, error, additionalSetStateFields) => {
        let errorText;
        switch (error.name) {
        case Constants.ERROR_TYPES.abort:
            errorText = Constants.FLASHBAR_STRINGS.flashbarTimeoutErrorText;
            break;
        case Constants.ERROR_TYPES.permission:
            errorText = Constants.FLASHBAR_STRINGS.flashbarNoPermission;
            break;
        case Constants.ERROR_TYPES.input:
            errorText = Constants.FLASHBAR_STRINGS.flashbarInvalidInput;
            break;
        default:
            errorText = HelperFunctions.replaceOrderStringsWithOrderLinks(error.message);
        }
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: Constants.FLASHBAR_TYPES.error,
                text: HelperFunctions.parseErrorText(errorText)
            }
        },
        additionalSetStateFields));
        // eslint-disable-next-line no-unreachable
        window.scrollTo(0, 0);
    };

    /*
     * This function displays a success message on the flashbar for a successful backend call.
     * NOTE: there is a "flashbar" state on every major page of the app that this function interacts with.
     */
    static displayFlashbarSuccess = (reactComponent, successText, additionalSetStateFields) => {
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: Constants.FLASHBAR_TYPES.success,
                text: successText
            }
        },
        additionalSetStateFields));
        window.scrollTo(0, 0);
    };

    /**
     * This function clears the flashbar on a page.
     */
    static dismissFlashbar = (reactComponent, additionalSetStateFields) => {
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: "",
                text: ""
            }
        },
        additionalSetStateFields));
    };

    /**
     * This method adds UUIDs to each object in the topologyMap. This ensures the bug described here
     * (https://stackoverflow.com/questions/46735483/error-do-not-use-array-index-in-keys) does not occur.
     * @returns order with topologyMap that contains UUIDs
     */
    static addUuidsToTopologyMap = (order) => {
        const topologyMapToModify = order.topologyMap;
        Object.keys(topologyMapToModify).forEach((topologyMapPosition) => {
            topologyMapToModify[topologyMapPosition].forEach((topologyObjectToModify) => {
                if (!topologyObjectToModify.uuid) {
                    // In this case, we want to modify the topologyMap directly as we loop through it so we
                    // disable the lint rule that disallows you from reassigning parameters for lambda loops
                    // eslint-disable-next-line no-param-reassign
                    topologyObjectToModify.uuid = uuidv4();
                }
            });
        });

        return order;
    }

    /**
     * This function handles error messages being prepended with ": "
     * @param errorText
     * @returns {any}
     */
    static parseErrorText = errorText => (typeof (errorText) === "string" && errorText.charAt(0) === ":"
    && errorText.charAt(1) === " " ? errorText.slice(2, errorText.length) : errorText);

    /**
     * Taking in number of items rather than the items list to prevent undefined errors
     */
    static assignShortTableClass = numberOfItems => (numberOfItems < 4 ? "fremont-short-table" : "");

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    static handleFlashBarMessagesFromChildTabs = (reactComponent, flashbarSuccessText, error, dismiss) => {
        if (flashbarSuccessText) {
            HelperFunctions.displayFlashbarSuccess(reactComponent, flashbarSuccessText);
        }
        if (error) {
            HelperFunctions.displayFlashbarError(reactComponent, error);
        }
        if (dismiss) {
            HelperFunctions.dismissFlashbar(reactComponent);
        }
    };

    /*
     * Method for formatting the correct date and time for various purposes.
     */
    static formatDateAndTime = (item, format) => {
        if (item && (moment(item, Constants.VALID_PARSING_FORMATS).isValid())) {
            const momentObject = moment.utc(item, Constants.VALID_PARSING_FORMATS);
            if (format) {
                return momentObject.format(format);
            }
            return momentObject;
        }
        return Constants.UNKNOWN_DATE;
    };

    /*
     * Helper function for parsing Decimals
     */
    static parseInt = str => (str ? parseInt(str, 10) : 0);

    static isStringInsideRange = (str, min, max) =>
        HelperFunctions.parseInt(str) >= min && HelperFunctions.parseInt(str) <= max;

    /*
     * This helper function generates a default circuitItemsObject that has all of the common values
     * used by each of the editable stages in a workflow
     * TODO: Add to this/create new function to add all the component display values to the circuit object
     */
    static generateStageCircuitItems = (staticCircuitObjects, dynamicCircuitObjects,
        isStageEditClicked, hasStageSubmittedOnce, isUpdateStageInProgress, handleStageInputChange,
        allBlockers, allFieldsDisabled) => {
        // Here we generate the circuitItemsObject that will be returned for a particular stage
        const circuitItems = {
            // The static part of the circuitItems comes from the circuitDesign objects which are defined at
            // the parent level. These static circuitItems are shown during display mode and do not change unless a
            // valid backend call is made successfully
            static: HelperFunctions.deepClone(staticCircuitObjects),
            // The dynamic part of the circuitItems comes from the updatedCircuitDesign objects which are
            // defined at the component level. The dynamic circuitItems are shown during edit mode and store changes
            // made by the user
            dynamic: HelperFunctions.deepClone(dynamicCircuitObjects)
        };
        if (circuitItems.static.length > 0) {
            circuitItems.static.forEach((staticCircuitObject) => {
                Object.assign(staticCircuitObject, {
                    editable: isStageEditClicked,
                    hasStageSubmittedOnce,
                    isUpdateStageInProgress,
                    allFieldsDisabled,
                    blockers: HelperFunctions.circuitsActiveBlockers(
                        [staticCircuitObject.circuitDesignId], allBlockers
                    )
                });
            });
        }
        if (circuitItems.dynamic.length > 0) {
            circuitItems.dynamic.forEach((dynamicCircuitObject) => {
                Object.assign(dynamicCircuitObject, {
                    editable: isStageEditClicked,
                    hasStageSubmittedOnce,
                    isUpdateStageInProgress,
                    handleStageInputChange,
                    allFieldsDisabled,
                    blockers: HelperFunctions.circuitsActiveBlockers(
                        [dynamicCircuitObject.circuitDesignId], allBlockers
                    )
                });
            });
        }
        // Sort the circuit designs in ascending order
        Object.keys(circuitItems).forEach((key) => {
            HelperFunctions.sortCircuitDesigns(circuitItems[key]);
        });
        return circuitItems;
    };

    /**
     * This function handles making a series of api calls asynchronously and throws an error if any of the calls fail
     * @param listOfApiCalls
     * @param flashBarErrorFunction
     * @returns {Promise<void>}
     */
    static handleAsynchronousCalls = async (listOfApiCalls, flashBarErrorFunction) => {
        // We are using Promise.all() rather than Promise.allSettled() because Promise.allSettled() is not compatible
        // with some of the older versions of Firefox and Safari (which means it will throw an exception). Since
        // Promise.all() usually breaks after the first unsuccessful request, we are making use of the catch() block
        // Promise.allSettled is not supported in Firefox 68.6.1esr.

        const catchHandler = () => ({ resolved: false });
        const successHandler = () => ({ resolved: true });
        await Promise.all(listOfApiCalls.map(request => request.then(successHandler).catch(catchHandler)))
            .then((requests) => {
                const failedRequests = requests.filter(request => !request.resolved);
                if (failedRequests.length > 0) {
                    // If any of the requests resolved with an error, show an error message to the user
                    flashBarErrorFunction(null, {
                        message: "Unable to load all necessary data from Fremont. Please refresh the page."
                    });
                }
            });
    };

    /**
     * This function fetches every provider item that currently exists in Fremont so that the user can choose from
     * a list of providers rather than trying to manually type in the name
     */
    static getAllProviderItems = async (auth) => {
        const fremontBackendClient = new FremontBackendClient();
        let providerOptions = [];
        let response = {};
        try {
            do {
                const nextToken = response.nextToken ? response.nextToken : null;
                // By default, lint does not allow using await in a loop. In this case, getAllProviderInfo returns a
                // token that is used for the next iteration which means we have to use await within a loop. For
                // lint to pass, we have to disable that error before that line, which is done below:
                // eslint-disable-next-line no-await-in-loop
                response = await fremontBackendClient.getAllProviderInfo(nextToken, auth);
                const activeProviders = response.providers
                    .filter(provider => Constants.STATUS.active === provider[Constants.ATTRIBUTES.status]);

                providerOptions = providerOptions.concat(HelperFunctions.createSelectedOptions(
                    activeProviders.map(provider => provider[Constants.ATTRIBUTES.providerName])
                ));
            }
            while (response.nextToken);
        } catch (error) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarErrorRetrievingProviders);
        }
        if (providerOptions.length === 0) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarNoProviders);
        }
        HelperFunctions.sortObjectsByField(providerOptions, "label");
        return providerOptions;
    };

    /**
     * This function fetches every tag  that currently exists in Fremont so that the user can choose from
     * a list of tags rather than trying to manually type in the name
     */
    static getAllTagItems = async (auth) => {
        const fremontBackendClient = new FremontBackendClient();
        const tagObjects = [];
        let response = {};
        try {
            do {
                const nextToken = response.nextToken ? response.nextToken : null;
                // By default, lint does not allow using await in a loop. In this case, getAllTagInfo returns a
                // token that is used for the next iteration which means we have to use await within a loop. For
                // lint to pass, we have to disable that error before that line, which is done below:
                // eslint-disable-next-line no-await-in-loop
                response = await fremontBackendClient.getAllTagInfo(nextToken, auth);
                tagObjects.push(...response.tags.map(tag => tag));
            }
            while (response.nextToken);
        } catch (error) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarErrorRetrievingTags);
        }
        return tagObjects;
    };

    /**
     * This function fetches every site item that currently exists in Fremont so that the user can choose from
     * a list of sites rather than trying to manually type in the name
     */
    static getAllSiteItems = async (auth) => {
        const fremontBackendClient = new FremontBackendClient();
        const siteOptions = [];
        let response = {};
        try {
            do {
                const nextToken = response.nextToken ? response.nextToken : null;

                // By default, lint does not allow using await in a loop. In this case, getAllSiteInfo returns a
                // token that is used for the next iteration which means we have to use await within a loop. For
                // lint to pass, we have to disable that error before that line, which is done below:
                // eslint-disable-next-line no-await-in-loop
                response = await fremontBackendClient.getAllSiteInfo(nextToken, auth);
                siteOptions.push(...response.sites.map(site => (
                    {
                        label: site.siteName,
                        value: site.siteId,
                        site
                    })));
            }
            while (response.nextToken);
        } catch (error) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarErrorRetrievingSites);
        }
        if (siteOptions.length === 0) {
            throw new Error(Constants.FLASHBAR_STRINGS.flashbarNoSites);
        }
        return siteOptions;
    };

    /*
     * This function generates a clickable link to an external url
     * Requires https:// to open external webpage
     */
    static renderExternalLink = url => (
        url &&
            <Link external href={new RegExp("^https?://").test(url) ? url : `https://${url}`}>
                {url}
            </Link>
    );

    /*
     * This function generates a list of providerNames links
     */
    static renderProviderNameLinks = (providerNameList) => {
        const providerLinks = [];
        providerNameList.forEach((providerName, i) => {
            providerLinks.push(
                <span key={providerName}>
                    <Link href={`${Constants.ROUTES.provider}/${providerName}`}>
                        {providerName}
                    </Link>
                    {(providerNameList.length === (i + Constants.MAP_INDEXES.valueIndex)) ? "" : ", "}
                </span>
            );
        });
        return providerLinks;
    };

    /*
     * This function replaces the order ids in a string with links to that order. Nice for error messages and audits.
     */
    static replaceOrderStringsWithOrderLinks = (str) => {
        const orderStrings = str.match(Constants.REGEX_PATTERNS.orderId);
        if (orderStrings) {
            const strings = str.split(Constants.REGEX_PATTERNS.orderId).map(section =>
                <span key={uuidv4()}>{section}</span>);
            const finalString = [strings[0]];
            for (let i = 1; i < strings.length; i += 1) {
                finalString.push(HelperFunctions.renderLink(Constants.ROUTES.order,
                    orderStrings[i - 1], orderStrings[i - 1]));
                finalString.push(strings[i]);
            }
            return finalString;
        }
        return str;
    }

    /*
     * This function returns a link based on a route, ID, and display values
     */
    static renderLink = (route, id, displayValue) => (
        <Link href={`${route}/${id}`} key={uuidv4()}>
            {displayValue}
        </Link>
    );

    /*
     * This function returns a list of links based on a route and an array of IDs and display values
     */
    static renderLinksFromIdAndDisplayValues = (route, idAndDisplayValueArray, showLineBreaker) => {
        const links = [];
        idAndDisplayValueArray.forEach((idAndLinkObject, i) => {
            links.push(
                <span key={idAndLinkObject[Constants.ID_AND_DISPLAY_VALUE.ID]}>
                    <Link href={`${route}/${idAndLinkObject[Constants.ID_AND_DISPLAY_VALUE.ID]}`}>
                        {idAndLinkObject[Constants.ID_AND_DISPLAY_VALUE.DISPLAY_VALUE]}
                    </Link>
                    {(showLineBreaker ||
                        (idAndDisplayValueArray.length === (i + Constants.MAP_INDEXES.valueIndex))) ? "" : ", "}
                </span>
            );
        });
        return links;
    };

    static returnIdInformation = (site, field) => {
        if (site === "siteA") {
            switch (field) {
            case Constants.COMPONENT_LABELS.router: return Constants.COMPONENT_NAMES.nodeA;
            case Constants.COMPONENT_LABELS.lever: return Constants.COMPONENT_NAMES.leverA;
            case Constants.COMPONENT_LABELS.interface: return Constants.COMPONENT_NAMES.portA;
            case Constants.COMPONENT_LABELS.leverExternalInterface:
                return Constants.COMPONENT_NAMES.leverAExternalInterface;
            case Constants.COMPONENT_LABELS.leverInternalInterface:
                return Constants.COMPONENT_NAMES.leverAInternalInterface;
            default: return "Invalid Input";
            }
        } else {
            switch (field) {
            case Constants.COMPONENT_LABELS.router: return Constants.COMPONENT_NAMES.nodeZ;
            case Constants.COMPONENT_LABELS.lever: return Constants.COMPONENT_NAMES.leverZ;
            case Constants.COMPONENT_LABELS.interface: return Constants.COMPONENT_NAMES.portZ;
            case Constants.COMPONENT_LABELS.leverExternalInterface:
                return Constants.COMPONENT_NAMES.leverZExternalInterface;
            case Constants.COMPONENT_LABELS.leverInternalInterface:
                return Constants.COMPONENT_NAMES.leverZInternalInterface;
            default: return "Invalid Input";
            }
        }
    };

    static returnHeaderInformation = (site, field) => {
        if (site === "siteA") {
            switch (field) {
            case Constants.COMPONENT_LABELS.router: return Constants.COMPONENT_HEADER.routerA;
            case Constants.COMPONENT_LABELS.lever: return Constants.COMPONENT_HEADER.leverA;
            case Constants.COMPONENT_LABELS.interface: return Constants.COMPONENT_HEADER.interfaceA;
            case Constants.COMPONENT_LABELS.leverExternalInterface:
                return Constants.COMPONENT_HEADER.leverAExternalInterface;
            case Constants.COMPONENT_LABELS.leverInternalInterface:
                return Constants.COMPONENT_HEADER.leverAInternalInterface;
            default: return "Invalid Input";
            }
        } else {
            switch (field) {
            case Constants.COMPONENT_LABELS.router: return Constants.COMPONENT_HEADER.routerZ;
            case Constants.COMPONENT_LABELS.lever: return Constants.COMPONENT_HEADER.leverZ;
            case Constants.COMPONENT_LABELS.interface: return Constants.COMPONENT_HEADER.interfaceZ;
            case Constants.COMPONENT_LABELS.leverExternalInterface:
                return Constants.COMPONENT_HEADER.leverZExternalInterface;
            case Constants.COMPONENT_LABELS.leverInternalInterface:
                return Constants.COMPONENT_HEADER.leverZInternalInterface;
            default: return "Invalid Input";
            }
        }
    };

    static constructLegacyPath = (path) => {
        // Escape special characters in the terms and create a regular expression pattern
        const escapedTerms = Object.values(Constants.LIGHTHOUSE_PREPENDED_PATHS)
            .map(term => term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
        const pattern = new RegExp(escapedTerms.join("|"), "gi");

        // Use the replace method to remove instances of terms in REMOVE
        return path.replace(pattern, "");
    }

    /*
     * This function renders a tooltip that might or might not be linked to various parts of the Fremont help page
     */
    static renderCustomTooltip = (link, displayText) => (
        <Popover
            content={
                link ?
                    <Link href={link}>
                        <Box
                            variant="p"
                        >
                            {displayText}
                        </Box>
                    </Link>
                    :
                    <Box
                        variant="p"
                    >
                        {displayText}
                    </Box>
            }
        >
            <Icon name="status-info" size="normal"/>
        </Popover>
    );

    /*
     * This function renders one of two CLLI code tooltips based on the site type that is passed in
     */
    static renderClliCodeTooltip = (link, siteType) => {
        if (siteType === SiteValidation.SITE_TYPE_NAMES.ilaHut
            || siteType === SiteValidation.SITE_TYPE_NAMES.manhole) {
            return HelperFunctions.renderCustomTooltip(link, Constants.TOOLTIP_STRINGS
                .networkSupportSiteClliCodeFieldExplanation);
        }
        return HelperFunctions.renderCustomTooltip(link, Constants.TOOLTIP_STRINGS
            .networkSiteClliCodeFieldExplanation);
    };

    /*
     * Creates a label with the pattern { label: <input>, value: <input> } from an input string. Used to display
     * selected values to the user
     */
    static createSelectedOption = input => (!input ? "" : { label: input, value: input });

    /*
     * Creates a label with the pattern { label: <input>, value: <input>, description } from an input string.
     * Used to display selected values to the user
    */
    static createSelectedOptionWithDescription = (input, descriptions) =>
        (!input ? "" : { label: input, value: input, description: descriptions[input] });

    static createSelectOptionsWithDescriptionsForComprehend = input =>
        (!input ? "" : {
            label: input.text,
            value: input.text,
            description: `Comprehend Confidence Score: ${input.confidenceScore.toFixed(2)}`
        });

    static createSelectedOptionsForComprehend = options => options.map(
        option => HelperFunctions.createSelectOptionsWithDescriptionsForComprehend(option)
    );

    /*
     * Creates a label with the pattern { label: Constants.BACKEND_TO_FRONTEND_MAP[<input>], value: <input> } from an
     * input string. Used to display selected values to the user
     */
    static createSelectedOptionWithFrontEndLabel = input => (!input ? "" :
        { label: Constants.BACKEND_TO_FRONTEND_MAP[input] || input, value: input });

    /*
     * This function takes a list of strings and returns a list of options in the correct format
     */
    static createSelectedOptions = input => input.map(option => HelperFunctions.createSelectedOption(option));

    /*
    * Creates a label with the pattern { label: <input>, value: <input>, descriptions } from an input string.
    * Used to display selected values to the user
    */
    static createSelectedOptionsWithDescriptions = (input, descriptions) =>
        input.map(option => HelperFunctions.createSelectedOptionWithDescription(option, descriptions));


    /*
    * This function takes a list of strings and returns a list of options in the correct format with frontend labels
    */
    static createSelectedOptionsWithFrontEndLabels = input =>
        input.map(option => HelperFunctions.createSelectedOptionWithFrontEndLabel(option));

    /*
     * This function determines whether or not a certain tab should be editable for a user
     */
    static determineIfTabIsEditable = (userPermissions, tabId) => {
        // If we are in a local environment, or the user is in the nest or admin posix group, the tab is editable
        if (HelperFunctions.isLocalHost() || HelperFunctions.isDevelopmentStack()
            || userPermissions[Constants.POSIX_GROUPS.NEST]) {
            return true;
        }

        // We know that correspondingPosixGroups will always return a list, since this function is only called
        // if the tabId is one of the keys of the TAB_TO_POSIX_GROUP_MAP
        const correspondingPosixGroups = Constants.TAB_TO_POSIX_GROUP_MAP[tabId];
        // If the user is in any posix group that can edit this tab, then we return true and they will
        // be able to edit the tab
        return correspondingPosixGroups.some(posixGroup => userPermissions[posixGroup]);
    }

    /*
    * This function takes a list of strings and returns a list of options in the correct format but with group
    */
    static createSelectedOptionWithGroup = (input, group) => (!input ? "" : { label: input, value: input, group });

    static createSelectedOptionsForGroup = (input, group) => input.map(option =>
        HelperFunctions.createSelectedOptionWithGroup(option, group));

    /*
     * This function takes a list of strings and returns a list of options in the correct format with a "None" option
     */
    static createSelectedOptionsWithNone = input =>
        [{ label: "None", value: "" }]
            .concat(input.map(option => HelperFunctions.createSelectedOption(option)));

    static addNoneSelectedOption = input => [{ label: "None", value: "" }].concat(input);

    static createSelectedOptionForAttachment = attachmentType => ({
        value: attachmentType, label: Constants.ATTACHMENT_TYPES_LABELS[attachmentType]
    });

    static createSelectedOptionsForAttachments = attachmentTypes =>
        attachmentTypes.map(attachmentType => HelperFunctions.createSelectedOptionForAttachment(attachmentType));

    /*
     * This function is used to generate the NetVane link that is displayed to users
     */
    static generateNetVaneLink = asn => (!asn.netVane ? "-" : <a href={asn.netVane}>{`AS${asn.asnNumber}`}</a>);

    /**
    * Handle errors in async functions go style. Returns either an err or data
    * const [err, data] = await goPromise(Promise.resolve("data"));
    * err and data will either be a valid value or undefined.
    */
    static goPromise = promise => promise.then(data => [undefined, data]).catch(err => [err]);

    /**
     * Gets correct thing to display for LOAs
     * @param loaDisposition
     * @returns {string}
     */
    static displayLoaDisposition = (loaDisposition) => {
        if (loaDisposition) {
            if (loaDisposition.includes(Constants.AMAZON)) {
                return Constants.LOA_DISPOSITIONS.amazon;
            }
            return Constants.LOA_DISPOSITIONS.provider;
        }
        return "";
    };


    /*
     * Removes an individual object from a dynamic input field and reassigns all the IDs to ensure they are unique
     */
    static subtractSpecificObjectHelper = (objects, objectIdToRemove, objectType, objectRequired) => {
        // First, we filter out the appropriate object based on its unique ID
        const objectsToReturn = objects.filter(object => object.id !== objectIdToRemove);
        // Next, we reassign all the IDs to ensure that no duplicates will exist when a new object is created
        objectsToReturn.forEach((object, i) => Object.assign(object, { id: `${objectType}${i + 1}` }));
        // If the objectField is not required and there is only one object left,
        // we want to make sure that no error text is present
        if (!objectRequired && objectsToReturn.length === 1) {
            // EscalationPath objects have two fields whose error text has to be set to a blank string
            if (objectType === Constants.DYNAMIC_INPUT_TYPES.escalationPath) {
                const onlyEscalationPathObject = objectsToReturn.find(object =>
                    object.id.includes(Constants.FIRST_INDEX_INDICATOR));
                // We only set the error messages on level and contactName to blank if neither field has a value
                if ((Object.keys(onlyEscalationPathObject.selectedOption).length === 0
                    && onlyEscalationPathObject.level === "")) {
                    objectsToReturn.filter(object =>
                        object.id.includes(Constants.FIRST_INDEX_INDICATOR)).map(object =>
                        Object.assign(object, { contactNameErrorText: "", levelErrorText: "" }));
                }
            } else {
                objectsToReturn.filter(object =>
                    object.id.includes(Constants.FIRST_INDEX_INDICATOR)).map(object =>
                    Object.assign(object, { errorText: "" }));
            }
        }
        return objectsToReturn;
    };

    /*
     * This function displays the lagCircuitCount and lagQuantity fields in a formatted fashion
     * if both fields are not blank
     */
    static displayCircuitCountAndQuantity = (lagCircuitCount, lagQuantity) => (
        !!lagCircuitCount && !!lagQuantity ? `${lagCircuitCount} x ${lagQuantity}` : ""
    );

    /*
     * This function returns a field displayed with an optional or required label based on whether
     * the field is required or not
     */
    static displayRequiredOrOptionalField = (field, required) => (
        required ? requiredLabel(field) : optionalLabel(field)
    );

    // Use at your own risk. Guidelines on potentially when not to use here: https://stackoverflow.com/a/122704/4392915
    static deepClone = obj => (obj ? JSON.parse(JSON.stringify(obj)) : obj);

    /*
     * Gets countries to populate select field with
     */
    static getCountryOptions = () => {
        const countryOptions = [];
        countries.all.forEach((country) => {
            if (country.emoji && country.name) {
                countryOptions.push({
                    label: country.name.concat(country.emoji),
                    value: country.name.concat(country.emoji)
                });
            }
        });
        countryOptions.sort((a, b) => ((a.label > b.label) ? 1 : -1));
        countryOptions.unshift({ label: "None", value: "" });
        return countryOptions;
    };

    /*
     * Gets currencies to populate select field with
     */
    static getCurrencyOptions = () => {
        const currencyOptions = [];
        currencies.all.forEach((currency) => {
            // Reasons for currency exclusion are described in the EXCLUDED_CURRENCIES array
            if (!(Constants.EXCLUDED_CURRENCIES.includes(currency.code))) {
                if (currency.symbol) {
                    currencyOptions.push({
                        label: `${currency.name} (${currency.symbol})`,
                        value: currency.code
                    });
                } else {
                    currencyOptions.push({
                        label: currency.name,
                        value: currency.code
                    });
                }
            }
        });
        currencyOptions.sort((a, b) => ((a.label > b.label) ? 1 : -1));
        currencyOptions.unshift({ label: "United States dollar ($)", value: "USD" });
        currencyOptions.unshift({ label: "None", value: "" });
        return currencyOptions;
    };

    /**
     * Finds the correct symbol for the currency name
     * @param currencyName
     * @returns {*} symbol or ""
     */
    static findCurrencySymbol = (currencyCode) => {
        const foundCurrency = currencies.all.find(currency =>
            currency.code === currencyCode);
        if (!foundCurrency || !foundCurrency.symbol) {
            return "";
        }
        return foundCurrency.symbol;
    }

    /*
     * Gets options for select component from 1 to num (useful for date/level selections)
     */
    static getNumericalOptions = (num) => {
        const options = [];
        let i;
        for (i = 1; i <= num; i += 1) {
            options.push({
                label: i.toString(),
                value: i.toString()
            });
        }
        options.unshift({ label: "None", value: "" });
        return options;
    };

    /*
     * Give how many days are in the month
     */
    static getDayOptions = (month) => {
        let days;
        if (month === "January" || month === "March" || month === "May" || month === "July"
            || month === "August" || month === "October" || month === "December") {
            days = 31;
        } else if (month === "April" || month === "June" || month === "September" || month === "November") {
            days = 30;
        } else {
            days = 29;
        }
        return HelperFunctions.getNumericalOptions(days);
    };

    static daysBetweenTwoDates = (date1, date2) => {
        const dateOne = !date1 ? "" : new Date(date1);
        const dateTwo = !date2 ? "" : new Date(date2);
        if (!dateOne || !dateTwo) {
            return "";
        }
        const difference = dateOne - dateTwo;
        const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return totalDays;
    }

    /*
     *  This function makes sure that required input is not blank.
     *  It is assumed that the input passed into this function has been trimmed of leading and trailing whitespace
     */
    static validateInfo = (input, inputType) => {
        if (input === "" || input === null) {
            if (inputType === Constants.VALIDATE_INFO_OPTIONS.required) {
                return Constants.ERROR_STRINGS.blankInput;
            }
            if (inputType === Constants.VALIDATE_INFO_OPTIONS.blankProvider) {
                return Constants.ERROR_STRINGS.blankProviderErrorText;
            }
            if (inputType === Constants.VALIDATE_INFO_OPTIONS.blankProviderService) {
                return Constants.ERROR_STRINGS.blankProviderServiceErrorText;
            }
            if (inputType === Constants.VALIDATE_INFO_OPTIONS.blankRegion) {
                return Constants.ERROR_STRINGS.blankRegionErrorText;
            }
            if (inputType === Constants.VALIDATE_INFO_OPTIONS.blankContact) {
                return Constants.ERROR_STRINGS.blankContactNameErrorText;
            }
            if (inputType === Constants.VALIDATE_INFO_OPTIONS.blankLevel) {
                return Constants.ERROR_STRINGS.blankLevelErrorText;
            }
            // Need to have a return statement here in case faulty inputType is passed through.
            // This return statement should never trigger but it's safe to have a default case
            return "Invalid input";
        }
        return "";
    };

    /*
     * This function is used for validating string length.
     */
    static validateStringLength = input => (input.length > Constants.MAX_DB_STRING_LENGTH ?
        Constants.VALIDATION_ERROR_STRINGS.stringLength : "");

    /*
     * This function is used to verify a CLLI Code in Network Site Code Format
     */
    static validateNetworkSiteClliCode = input => (/^[A-Z]{6}[A-Z0-9]{2}$/.test(input) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.networkSiteClliCode);

    /*
     * This function is used to verify a CLLI Code in Network Support Site Code Format
     */
    static validateNetworkSupportSiteClliCode = input => (/^[A-Z]{7}[0-9]{4}$/.test(input) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.networkSupportSiteClliCode);

    /*
     * This function is used for validating phone and fax number.
     */
    static validatePhoneOrFaxNumber = (input, phoneOrFax) => {
        const phoneOrFaxNumberRegex = /^\d{7,}$/;
        // True phone number validation can only be done via external libraries github.com/google/libphonenumber
        // This validation is removing special characters such as 'ext' '+' '.'
        // eslint-disable-next-line no-useless-escape
        if (phoneOrFaxNumberRegex.test(input.replace(/[\s()+\-\.]|ext/gi, ""))) {
            return "";
        }

        if (phoneOrFax === Constants.PHONE_OR_FAX.phone) {
            return Constants.VALIDATION_ERROR_STRINGS.phone;
        }
        return Constants.VALIDATION_ERROR_STRINGS.fax;
    };

    /*
     * This function is used for validating email.
     */
    static validateEmail = input => (Constants.REGEX_PATTERNS.email.test(input) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.email);

    /*
     * This function is used for validating website.
     */
    static validateWebsite = input => (input.match(Constants.REGEX_PATTERNS.website) ? "" : "Invalid website");

    /*
     * This function is used for validating input to only have letters (including accents) and spaces.
     */
    static validateLettersAndSpaces = input => (/^[A-zÀ-ÿ-\s]+$/.test(input) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.lettersAndSpaces);

    /*
     * This function is used for validating input to only have numbers.
     */
    static validateNumeric = input => (/^[0-9]+$/.test(input) ? "" : Constants.VALIDATION_ERROR_STRINGS.numeric);

    /*
     * This function is used for validating input to only have numbers.
     */
    static validateDecimal = input => (/^-?\d*(\.\d+)?$/.test(input) ? "" : Constants.VALIDATION_ERROR_STRINGS.decimal);

    /*
     * This function validates input to be an airport code (exactly three capital letters)
     */
    static validateAirportCode = input => (/^[A-Z]{3}$/.test(input) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.airportCode);

    /*
     * This function is used for validating input to only have alpha numeric characters.
     */
    static validateAlphaNumeric = input => (/^[A-zÀ-ÿ0-9]+$/.test(input) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.alphaNumeric);

    /*
     * This function is used for validating input to only have a valid ipv4 address.
     *  https://www.regexpal.com/104036
     */
    static validateIPv4 = ipAddress => (ipAddress.match(Constants.REGEX_PATTERNS.ipv4Regex) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.ipv4);

    /*
     * This function is used for validating input to only have a valid ipv4 address without a subnet.
     */
    static validateIPv4WithoutSubnet = ipAddress => (
        ipAddress.match(Constants.REGEX_PATTERNS.ipv4WithoutSubnetRegex)
            ? "" : Constants.VALIDATION_ERROR_STRINGS.ipv4);

    /*
     * This function is used for validating input to only have a valid ipv6 address.
     */
    static validateIPv6 = ipAddress => (ipAddress.match(Constants.REGEX_PATTERNS.ipv6Regex) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.ipv6);

    /*
     * This function is used for validating input to only have a valid ipv6 address without a subnet.
     */
    static validateIPv6WithoutSubnet = ipAddress => (
        ipAddress.match(Constants.REGEX_PATTERNS.ipv6WithoutSubnetRegex)
            ? "" : Constants.VALIDATION_ERROR_STRINGS.ipv6);

    /*
     * This function is used for validating input to only have a valid tt url.
     */
    static validateTTURL = ttURL => (new RegExp("^(https://|^)(t.corp.amazon.com/|tt.amazon.com/|issues.amazon.com/|sim.amazon.com/).*").test(ttURL) ? "" :
        Constants.VALIDATION_ERROR_STRINGS.ttURL);

    static validateNode = (node, site) => (
        RegExp("^[a-z]{3}[0-9]{1,3}-(br|en)-(cor|bfb|fnc|tra|spc)[a-z0-9-]*-r[0-9]{1,2}$").test(node)
        // We are doing the regex check first so the below splitting is safe
        && node.slice(0, 3).toLowerCase() === site.slice(0, 3).toLowerCase()
        && HelperFunctions.parseInt(node.slice(3).split("-")[0]) === HelperFunctions.parseInt(site.slice(3))
    );

    static validateLever = (lever, site) => (
        RegExp("^[a-z]{3}[0-9]{1,3}-br-eng-sw[0-9]+$|^[a-z]{3}[0-9]{1,3}-br-bfb-f[0-9]-b[0-9]+-eng-sw[0-9]+$")
            .test(lever)
        // We are doing the regex check first so the below splitting is safe
        && lever.slice(0, 3).toLowerCase() === site.slice(0, 3).toLowerCase()
        && HelperFunctions.parseInt(lever.slice(3).split("-")[0]) === HelperFunctions.parseInt(site.slice(3))
    );

    static validatePort = port => (RegExp("^(et-([0-9]+)/([0-9]+)/([0-9]+):([0-9]+))$|^((et|xe|ge)-([0-9]+)/([0-9]+)"
        + "/([0-9]+))$|^((te|tengigabitethernet|gigabitethernet)[0-9]+/[0-9]+)$|^jrp[0-9]+(-[0-9]+)?$").test(port));

    static validateLeverInterface = leverInterface => (RegExp("^jrp[0-9]+(-[0-9]+)?$").test(leverInterface));

    static validateLag = lag => (RegExp("^(ae|port-channel|bond)[0-9]+$").test(lag));

    static booleanToString = (bool) => {
        if (bool === true) {
            return Constants.TRUE_STRING;
        }
        if (bool === false) {
            return Constants.FALSE_STRING;
        }
        return null;
    };

    static parseBoolean = (boolString) => {
        if (boolString === Constants.TRUE_STRING) {
            return true;
        }
        if (boolString === Constants.FALSE_STRING) {
            return false;
        }
        return null;
    };

    static booleanToYesNo = bool => (bool ? Constants.YES_NO.yes : Constants.YES_NO.no);

    static booleanToYesNoSelectedOption = bool => (bool ?
        {
            label: Constants.YES_NO.yes,
            value: true
        }
        :
        {
            label: Constants.YES_NO.no,
            value: false
        }
    );

    static booleanToYesNoNullSelectedOption = (bool) => {
        if (bool === null) {
            return {
                value: null, label: "No Selection"
            };
        }
        return HelperFunctions.booleanToYesNoSelectedOption(bool);
    }

    /**
     * This function returns a unique list of every field that is disabled based on the current in progress stage(s)
     */
    static getDisabledFields = (stages, stageStatusMap) => {
        const disabledFieldsSet = new Set();
        // To get all disabled fields, we filter down to only stages that are in progress, add each disabled field
        // for that stage to a set, and turn it back into an array when we return it
        Object.keys(stages).filter(stageName =>
            HelperFunctions.isStageInProgress(stageStatusMap[stageName]))
            .forEach(stageName => stages[stageName].disabledFields.forEach(disabledField =>
                disabledFieldsSet.add(disabledField)));
        return Array.from(disabledFieldsSet);
    };

    /**
     * This method display the status of a stageStausMap.
     * @param objectWithStageStatusMap order or circuit object whose stage status map we will inspect
     */
    static displayCurrentInProgressStages = (objectWithStageStatusMap) => {
        if (!objectWithStageStatusMap || !objectWithStageStatusMap.stageStatusMap) {
            return "-";
        }
        if (HelperFunctions.isOrderCompleted(objectWithStageStatusMap.stageStatusMap)) {
            return "Complete";
        }
        if (HelperFunctions.isOrderCancelled(objectWithStageStatusMap.stageStatusMap)) {
            return "Cancelled";
        }
        const inProgressStages = Object.keys(objectWithStageStatusMap.stageStatusMap).filter(stageName =>
            HelperFunctions.isStageInProgress(objectWithStageStatusMap.stageStatusMap[stageName]));
        return inProgressStages.map(inProgressStage => Constants.BACKEND_TO_FRONTEND_STAGE_MAP[inProgressStage])
            .join(", ");
    };

    /**
     * Looks at the stage status map and displays status accordingly, used for Orders and Circuits for tables
     * @param item
     * @returns {JSX.Element}
     */
    static displayStageStatus = (item) => {
        if (HelperFunctions.isOrderCancelled(item.stageStatusMap)) {
            return (
                <IconWithColoredText
                    iconName="status-negative"
                    type={Constants.ICON_TEXT_TYPES.error}
                    text="Cancelled"
                />
            );
        }
        const numberOfStages = Object.keys(item.stageStatusMap).length;
        const numberOfCompletedStages = Object.keys(item.stageStatusMap)
            .filter(stageName => HelperFunctions.isStageCompleted(item.stageStatusMap[stageName])).length;
        if (numberOfStages === numberOfCompletedStages) {
            return (
                <IconWithColoredText
                    iconName="status-positive"
                    type={Constants.ICON_TEXT_TYPES.success}
                    text="Completed"
                />
            );
        }
        return (
            <IconWithColoredText
                iconName="status-in-progress"
                type={Constants.ICON_TEXT_TYPES.secondaryText}
                text={Object.keys(item.stageStatusMap)
                    .filter(stageName => HelperFunctions.isStageInProgress(item.stageStatusMap[stageName]))
                    .map(stageName => Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stageName]).join(", ")}
            />
        );
    }

    /**
     * This function returns true if all of the orders stage are in the "Completed" stage and false otherwise
     */
    static isOrderCompleted = stageStatusMap => !!stageStatusMap && Object.keys(stageStatusMap).every(stageName =>
        HelperFunctions.isStageCompleted(stageStatusMap[stageName]));

    static isOrderCancelled = stageStatusMap => !!stageStatusMap && Object.keys(stageStatusMap).some(stageName =>
        HelperFunctions.isStageCancelled(stageStatusMap[stageName]));

    static isNCISOrder = order => order.meta && order.meta.dataSource === Constants.NCIS_DATASOURCE;

    static isStageNotStarted = stageStatus => (stageStatus === Constants.STAGE_STATUSES.notStarted);

    static isStageInProgress = stageStatus => (stageStatus === Constants.STAGE_STATUSES.inProgress);

    static isStageCompleted = stageStatus => (stageStatus === Constants.STAGE_STATUSES.completed);

    static isStageCancelled = stageStatus => (stageStatus === Constants.STAGE_STATUSES.cancelled);

    static hasNCISMeta = meta => !!meta && meta.dataSource === Constants.NCIS_DATASOURCE
        && meta.externalId && meta.externalName;

    static hasOrderTransientFields = order => order && order.meta && order.meta.transientFields;
    static getFieldFromFirstCircuitObject = (circuitDesignObjects, fieldName, defaultValue) => {
        if (circuitDesignObjects.length>0 && circuitDesignObjects.find(Boolean)[fieldName]) {
            return circuitDesignObjects.find(Boolean)[fieldName];
        }
        return defaultValue;
    }

    /**
     * While we can easily generate the NCIS url based off of our meta within our anchor tag, but in order to be able
     * to search for these items in the Polaris tables using its native search feature, we need them as fields on the
     * actual records. This method add those additional fields on our records
     * @param items List of items to add NCIS meta fields to
     * @param ncisBaseUrl baseURL that can be used to generate the link to NCIS
     */
    static appendMetaToItems = (items, ncisBaseUrl) => {
        if (!items) return items;
        items.forEach((item) => { HelperFunctions.appendMetaToItem(item, ncisBaseUrl); });
        return items;
    };

    /**
     * While we can easily generate the NCIS url based off of our meta within our anchor tag, but in order to be able
     * to search for these items in the Polaris tables using its native search feature, we need them as fields on the
     * actual records. This method add those additional fields on our records
     * @param item item to add NCIS meta fields to
     * @param ncisBaseUrl baseURL that can be used to generate the link to NCIS
     */
    static appendMetaToItem = (item, ncisBaseUrl) => {
        if (!item) return item; // If its undefined or something
        if (HelperFunctions.hasNCISMeta(item.meta)) {
            const externalId = item.meta.externalId.split("_")[0];
            Object.assign(item,
                {
                    ncisUrl: `${ncisBaseUrl}/${externalId}/view`,
                    ncisExternalId: item.meta.externalId,
                    ncisLabel: item.meta.externalName
                });
        }
        return item;
    };

    /**
     * Adds meta and statusColumn to circuits or objects
     * @param objects array of objects to add to
     * @param objectType from Constants.NCIS_ROUTES
     * @returns {*} modified objects
     */
    static addMetaAndStatus = (objects, objectType) => {
        // Need to clone objects here otherwise when objectStatusColumnIcon is put onto the original object and someone
        // tries to clone the original object again, the <span> put in the object as part of the displayStageStatus
        // call contains a circular reference to the object because span.props.children._owner.stateNode.props.items
        // might contain the objects here (this is why you should never modify objects directly)
        const clonedObjects = HelperFunctions.deepClone(objects);
        HelperFunctions.appendMetaToItems(clonedObjects, objectType)
            // We'll run into weird exceptions when processing an empty order object (i.e while loading)
            .filter(object => !HelperFunctions.isEmpty(object))
            .forEach((object) => {
                const objectStatusColumnIcon = HelperFunctions.displayStageStatus(object);
                if (HelperFunctions.isOrderCancelled(object.stageStatusMap)) {
                    Object.assign(object, { objectStatusColumnSearchable: "Cancelled", objectStatusColumnIcon });
                } else if (HelperFunctions.isOrderCompleted(object.stageStatusMap)) {
                    Object.assign(object, { objectStatusColumnSearchable: "Completed", objectStatusColumnIcon });
                } else {
                    const inProgressStages = Object.keys(object.stageStatusMap)
                        .filter(stageName => HelperFunctions.isStageInProgress(object.stageStatusMap[stageName]))
                        .map(stageName => Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stageName]).join(", ");
                    Object.assign(object, { objectStatusColumnSearchable: inProgressStages, objectStatusColumnIcon });
                }
            });
        return clonedObjects;
    };

    static showStageStatusWithBlocker = (stageStatus, blockers) => {
        let iconName;
        let type;
        let text;

        if (!!blockers && blockers.length > 0) {
            iconName = "status-negative";
            type = Constants.ICON_TEXT_TYPES.error;
            text = "Blocked";
        }
        if (HelperFunctions.isStageCancelled(stageStatus)) {
            iconName = "status-negative";
            type = Constants.ICON_TEXT_TYPES.error;
            text = "Cancelled";
        }
        if (HelperFunctions.isStageCompleted(stageStatus)) {
            iconName = "status-positive";
            type = Constants.ICON_TEXT_TYPES.success;
            text = "Completed";
        }
        if (HelperFunctions.isStageInProgress(stageStatus)) {
            iconName = "status-in-progress";
            type = Constants.ICON_TEXT_TYPES.secondaryText;
            text = "In Progress";
        }
        if (HelperFunctions.isStageNotStarted(stageStatus)) {
            iconName = "status-pending";
            type = Constants.ICON_TEXT_TYPES.secondaryText;
            text = "Not Started";
        }

        if (!text) {
            return <span/>;
        }

        return (
            <IconWithColoredText
                iconName={iconName}
                type={type}
                text={text}
            />
        );
    };

    static isBackboneService = serviceType => serviceType === Constants.SERVICE_TYPES.BACKBONE;

    static isInterconnectService = serviceType => Constants.INTERCONNECT_SERVICE_TYPES.includes(serviceType);

    static isUserInUpdatePermissionsGroup = user =>
        !!user.permissions[Constants.POSIX_GROUPS.FREMONT_UPDATE_ORDER_PRIORITY];

    static isOrderPathOrder = order =>
        HelperFunctions.isBackboneService(order.serviceType)
            && Constants.PATH_CUSTOMER_FABRICS.includes(order.customerFabric);

    static isOrderInterconnectChange = order =>
        !HelperFunctions.isBackboneService(order.serviceType)
        && HelperFunctions.isOrderChangeOrder(order);

    static isOrderSpanOrder = order =>
        HelperFunctions.isBackboneService(order.serviceType)
            && !Constants.PATH_CUSTOMER_FABRICS.includes(order.customerFabric);

    static isProviderBackboneOpticalUat = providerName =>
        !HelperFunctions.isProd() && Constants.BACKBONE_OPTICAL_UAT_PROVIDER === providerName;

    static isProviderAmazonInternal = providerName =>
        Constants.INTERNAL_AMAZON_PROVIDER === providerName
        || HelperFunctions.isProviderBackboneOpticalUat(providerName);

    static isOrderChangeOrder = order => Constants.ORDER_TYPES.CHANGE === order.orderType;

    static isOrderDecomOrder = order => Constants.ORDER_TYPES.DECOMMISSION === order.orderType;

    static doCircuitsHaveParents = circuits =>
        circuits.filter(circuit => !!circuit[Constants.ATTRIBUTES.consumedByCircuitId]
            || !!circuit[Constants.ATTRIBUTES.consumedByCircuitIdForNewRevision]).length > 0;

    /**
     * Returns true if the circuits have different pathIds
     */
    static circuitsHaveMultiplePaths = circuitItems => Array.from(new Set(
        circuitItems.map(circuit => circuit.pathId)
    )).length > 1;

    static getStageCompletionDate = (stage, order) => {
        // Convert the completion Date and creationDate into UTC time
        // then find the number of days in between them.
        const completionDate = Date.parse(order.requiredCompletionDate);
        const creationDate = Date.parse(order.createdTime);
        const daysToComplete = (completionDate - creationDate) / (1000 * 60 * 60 * 24);

        // Use our table to get this order type's specific list of median completion dates
        // Also use different table based on order type
        let table;
        if (Constants.BILLING_ORDER_TYPES.concat(
            [Constants.ORDER_TYPES.CHANGE, Constants.ORDER_TYPES.FABRIC_MIGRATION]
        ).includes(order.orderType)) {
            return "";
        }
        if (order.orderType === Constants.ORDER_TYPES.INSTALL) {
            table = Constants.COMPLETION_DATE_TABLE_INSTALL.filter(
                orderType => orderType.label === order.serviceType
            ).shift();
        }
        if (order.orderType === Constants.ORDER_TYPES.DECOMMISSION) {
            table = Constants.COMPLETION_DATE_TABLE_DECOM.filter(
                serviceType => serviceType.label === order.serviceType
            ).shift();
        }

        // Calculate the new stage completion date based on assigned completionDate and median completion dates
        const ratio = daysToComplete / table.daysTable.median;
        const completionTimeThisStage = HelperFunctions.parseInt(table.daysTable[stage]) * ratio;
        const adjustedStageCompletionDate = new Date(creationDate + (completionTimeThisStage * 24 * 60 * 60 * 1000));

        // return the date as a string only if the stage is not completed
        // since currently we are not storing completion dates by stage
        return HelperFunctions.isStageCompleted(order.stageStatusMap[stage]) ? "" :
            `Expected Completion Date: ${adjustedStageCompletionDate.toLocaleDateString()}`;
    };

    /*
     * This method returns a string that will be displayed for an orders service type. It handles which fields should
     * be returned based on the orders service type and order type
     */
    static getOrderServiceTypeCustomerFabricString = (order) => {
        if (Constants.ORDER_TYPES.FABRIC_MIGRATION === order.orderType) {
            return `${order.serviceType} : ${order.originalCustomerFabric} ${Constants.RIGHT_ARROW_UNICODE} ${order.customerFabric}`;
        } else if (Constants.SERVICE_TYPES.IP_TRANSIT === order.serviceType) {
            return order.serviceType + (order.transitType ? ` : ${order.transitType}` : "");
        } else if (Constants.SERVICE_TYPES.BACKBONE === order.serviceType) {
            return `${order.serviceType} : ${order.customerFabric}`;
        }

        return order.serviceType;
    }

    static displayInProgressStagesWithBlocker = (stageStatusMap, blockers) => {
        let stages;
        const inProgressStages = Object.keys(stageStatusMap)
            .filter(stageName => HelperFunctions.isStageInProgress(stageStatusMap[stageName]));
        if (inProgressStages.length > 0) {
            stages = inProgressStages.map(stageName => (
                <div key={stageName}>
                    <IconWithColoredText
                        iconName="status-in-progress"
                        type={Constants.ICON_TEXT_TYPES.secondaryText}
                        text={Constants.BACKEND_TO_FRONTEND_STAGE_MAP[stageName]}
                    />
                </div>
            ));
        }
        if (blockers.length > 0 && !!stages) {
            return (
                <IconWithColoredText
                    iconName="status-negative"
                    type={Constants.ICON_TEXT_TYPES.error}
                    text="Blocked"
                />
            );
        } else if (inProgressStages.length > 0) {
            return stages;
        }
        return (
            <IconWithColoredText
                iconName="status-positive"
                type={Constants.ICON_TEXT_TYPES.success}
                text="Completed"
            />
        );
    };

    /**
     * This method returns a boolean. True if the object passed in has field that
     * only a circuit object has, false otherwise. Only the circuit object has the revisionNumber attribute.
     * @param object object to check
     * @return true if object is circuit, false otherwise
     */
    static isObjectCircuit = object => !!object[Constants.ATTRIBUTES.revisionNumber];

    /**
     * This method takes in a list of circuit objects and returns the highest version of every logical circuit.
     * @param circuitDesignObjects list of circuit objects to mutate
     * @return list of circuits where every circuit will have a different circuitDesignNumber and each circuit
     *              will be the most recent non cancelled version
     */
    static returnMostRecentNonCancelledVersionOfCircuits = (circuitDesignObjects) => {
        const circuitRevisionNumberMap = {};
        circuitDesignObjects.forEach((circuitDesign) => {
            // We will never put a cancelled circuit as the most recent version of a circuit
            if (Constants.LIFECYCLE_STAGES.cancelled !== circuitDesign[Constants.ATTRIBUTES.lifeCycleStage]) {
                const existingCircuitInMap = circuitRevisionNumberMap[circuitDesign.circuitDesignNumber];
                if (!existingCircuitInMap
                    || (existingCircuitInMap.circuitDesignNumber === circuitDesign.circuitDesignNumber
                    && existingCircuitInMap.revisionNumber < circuitDesign.revisionNumber)) {
                    circuitRevisionNumberMap[circuitDesign.circuitDesignNumber] = circuitDesign;
                }
            }
        });
        return Object.values(circuitRevisionNumberMap);
    };

    /*
     * This method returns true if any of the blockers provided contain any of the circuitDesignIds provided,
     * false otherwise. Takes a list of circuitDesignObjects as a parameter along with the blockers
     */
    static anyActiveCircuitBlockersInOrder = (circuitDesignObjects, blockers) => {
        const circuitDesignIds = circuitDesignObjects.map(
            circuitDesign => circuitDesign.circuitDesignId
        );
        return circuitDesignIds.some(circuitDesignId =>
            blockers.some(blocker =>
                blocker.status === Constants.STATUS.active && blocker.circuitDesignIdList.includes(circuitDesignId)));
    };

    /*
     * This method returns a list of blockers who are active and contain one of the circuitDesignIds passed in
     * as a part of the circuitDesignIdList
     */
    static circuitsActiveBlockers = (circuitDesignIdList, blockers) => blockers.filter(
        blocker => blocker.status === Constants.STATUS.active && circuitDesignIdList.some(circuitDesignId =>
            blocker.circuitDesignIdList.includes(circuitDesignId))
    );

    static findComponent = (positionMap, componentName) => (
        !positionMap ? null : Object.values(positionMap).find(positionObject =>
            positionObject[Constants.COMPONENT_KEYS.name]
            === componentName)
    );

    static findComponentIndex = (positionMap, componentName) => (
        !positionMap ? null : Object.values(positionMap).findIndex(positionObject =>
            positionObject[Constants.COMPONENT_KEYS.name]
            === componentName)
    );

    static findComponentByUUID = (positionMap, uuid) => (
        !positionMap ? null : Object.values(positionMap).find(positionObject =>
            positionObject[Constants.COMPONENT_KEYS.uuid]
            === uuid)
    );

    static getConsumedCircuitIdsFromPositionMap = positionMap => (
        Object.values(positionMap).filter(positionObject =>
            Constants.COMPONENT_NAMES.fremontCircuit === positionObject.componentGroup)
            .map(positionObject => positionObject.uuid)
    );

    /**
     * This method retrieves the display value from a component's id
     * Note: this won't work with custom components
     * Return "" if component does not exist
     */
    static getDisplayValueFromComponentId = (componentIdToObjectMap, componentType, componentId, unitField) => {
        if (componentIdToObjectMap) {
            if (componentId) {
                const component = componentIdToObjectMap[componentId];
                if (component) {
                    switch (componentType) {
                    case Constants.COMPONENT_TYPES.site:
                        return component.siteName;
                    case Constants.COMPONENT_TYPES.node:
                        return component.deviceName;
                    case Constants.COMPONENT_TYPES.port:
                        return component.interfaceName;
                    case Constants.COMPONENT_TYPES.lag:
                        return component.interfaceName;
                    case Constants.COMPONENT_TYPES.providerCircuit:
                        return component.providerCircuitName;
                    case Constants.COMPONENT_TYPES.demarcAndCfa:
                        return component.assignmentId;
                    case Constants.COMPONENT_TYPES.unit:
                        switch (unitField) {
                        case Constants.ATTRIBUTES.amazonIPv4:
                            return component[Constants.ATTRIBUTES.amazonIPv4];
                        case Constants.ATTRIBUTES.providerIPv4:
                            return component[Constants.ATTRIBUTES.providerIPv4];
                        case Constants.ATTRIBUTES.amazonIPv6:
                            return component[Constants.ATTRIBUTES.amazonIPv6];
                        case Constants.ATTRIBUTES.providerIPv6:
                            return component[Constants.ATTRIBUTES.providerIPv6];
                        case Constants.ATTRIBUTES.bgpIPv4MD5Key:
                            return component[Constants.ATTRIBUTES.bgpIPv4MD5Key];
                        case Constants.ATTRIBUTES.bgpIPv6MD5Key:
                            return component[Constants.ATTRIBUTES.bgpIPv6MD5Key];
                        default:
                            return `Amazon IPv4: ${component[Constants.ATTRIBUTES.amazonIPv4]}, Provider IPv4: ${component[Constants.ATTRIBUTES.providerIPv4]}`;
                        }
                    default:
                        // return error?
                        return "";
                    }
                }
            }
        }
        return "";
    };

    static getCustomComponentForSiteGroup = (positionMap, customComponentType, site) => {
        const siteComponent = HelperFunctions.findComponent(positionMap, site);
        if (positionMap) {
            // we need a for loop here as we can break out of loop sooner...slightly more efficient
            let index;
            for (index = 0; index < Object.keys(positionMap).length; index += 1) {
                const component = positionMap[index];
                if (component.componentGroup === customComponentType && component.siteGroup === siteComponent.uuid) {
                    return component.name;
                }
            }
        }
        return "";
    }

    /**
     * Gets a component from positionMap and then gets that component's customAttributeId
     */
    static getCustomAttributeId = (componentIdToObjectMap, positionMap, componentName) => {
        const positionMapComponent = HelperFunctions.findComponent(positionMap, componentName);
        if (positionMapComponent) {
            const componentId = positionMapComponent[Constants.COMPONENT_KEYS.uuid];
            if (componentId) {
                const component = componentIdToObjectMap[componentId];

                if (component && component.customAttributeId) {
                    return component.customAttributeId;
                }
            }
        }
        return "";
    }

    /**
     * Gets a component from positionMap and then gets that component's customAttributeId, and then
     * parses the customAttributeValue to get desired customAttribute field.
     */
    static getDisplayValueFromCustomAttribute = (componentIdToObjectMap, positionMap, componentName,
        customAttribute) => {
        const positionMapComponent = HelperFunctions.findComponent(positionMap, componentName);

        if (positionMapComponent) {
            const componentId = positionMapComponent[Constants.COMPONENT_KEYS.uuid];

            if (componentId) {
                const component = componentIdToObjectMap[componentId];
                if (component && component.customAttributeId) {
                    const parsedCustomAttributeValueObject =
                        JSON.parse(componentIdToObjectMap[
                            component.customAttributeId][Constants.ATTRIBUTES.customAttributeValue]);
                    return parsedCustomAttributeValueObject[customAttribute];
                }
            }
        }
        return "";
    }

    /**
     * This method retrieves the display value from a component name
     * Note: this won't work with custom components
     * Return "" if component does not exist
     */
    static getDisplayValueFromComponentName = (componentIdToObjectMap, positionMap, componentName, unitField) => {
        const positionMapComponent = HelperFunctions.findComponent(positionMap, componentName);
        if (positionMapComponent) {
            // If there is a UUID on the position map component, check to see if its in our componentIdToObjectMap
            const componentId = positionMapComponent[Constants.COMPONENT_KEYS.uuid];
            if (componentId) {
                const component = componentIdToObjectMap[componentId];
                if (component) {
                    switch (positionMapComponent[Constants.COMPONENT_KEYS.type]) {
                    case Constants.COMPONENT_TYPES.site:
                        return component.siteName;
                    case Constants.COMPONENT_TYPES.node:
                        return component.deviceName;
                    case Constants.COMPONENT_TYPES.port:
                        return component.interfaceName;
                    case Constants.COMPONENT_TYPES.lag:
                        return component.interfaceName;
                    case Constants.COMPONENT_TYPES.providerCircuit:
                        return component.providerCircuitName;
                    case Constants.COMPONENT_TYPES.demarcAndCfa:
                        return component.assignmentId;
                    case Constants.COMPONENT_TYPES.unit:
                        switch (unitField) {
                        case Constants.ATTRIBUTES.amazonIPv4:
                            return component[Constants.ATTRIBUTES.amazonIPv4];
                        case Constants.ATTRIBUTES.providerIPv4:
                            return component[Constants.ATTRIBUTES.providerIPv4];
                        case Constants.ATTRIBUTES.amazonIPv6:
                            return component[Constants.ATTRIBUTES.amazonIPv6];
                        case Constants.ATTRIBUTES.providerIPv6:
                            return component[Constants.ATTRIBUTES.providerIPv6];
                        case Constants.ATTRIBUTES.bgpIPv4MD5Key:
                            return component[Constants.ATTRIBUTES.bgpIPv4MD5Key];
                        case Constants.ATTRIBUTES.bgpIPv6MD5Key:
                            return component[Constants.ATTRIBUTES.bgpIPv6MD5Key];
                        default:
                            return `Amazon IPv4: ${component[Constants.ATTRIBUTES.amazonIPv4]}, Provider IPv4: ${component[Constants.ATTRIBUTES.providerIPv4]}`;
                        }
                    default:
                        // return error?
                        return "";
                    }
                }
            }
        }
        return "";
    };

    static getAttributeFromComponent = (componentIdToObjectMap, positionMap, componentName, attributeName) => {
        const positionMapComponent = HelperFunctions.findComponent(positionMap, componentName);
        if (positionMapComponent) {
            // If there is a UUID on the position map component, check to see if its in our componentIdToObjectMap
            const componentId = positionMapComponent[Constants.COMPONENT_KEYS.uuid];
            if (componentId) {
                const component = componentIdToObjectMap[componentId];
                if (component) {
                    return component[attributeName] || "";
                }
            }
        }
        return "";
    };

    /**
     * This method is used for fetching site name links from circuit designs
     */
    static getSiteLink = (siteComponentName, circuitDesignObjects, componentIdToObjectMap) => {
        if (circuitDesignObjects.length === 0 || !componentIdToObjectMap) {
            return "-";
        }
        const { positionMap } = circuitDesignObjects.find(Boolean);
        let site = false;
        const siteComponent = HelperFunctions.findComponent(positionMap, siteComponentName);
        if (siteComponent && siteComponent[Constants.COMPONENT_KEYS.uuid]) {
            site = componentIdToObjectMap[siteComponent[Constants.COMPONENT_KEYS.uuid]];
        }
        return site ?
            <Link href={`${Constants.ROUTES.site}/${site[Constants.ATTRIBUTES.siteId]}`}>
                {site[Constants.ATTRIBUTES.siteName]}
            </Link> : "-";
    };

    /**
     * Gets the number of unique lags (by deviceNameInterfaceName) in a list of cirucit designs
     * @param circuitDesigns
     * @param componentIdToObjectMap
     * @returns {number} number of unique lags (by deviceNameInterfaceName)
     */
    static getNumberOfUniqueLags = (circuitDesigns, componentIdToObjectMap) => {
        const lagSet = new Set();
        circuitDesigns.forEach((circuitDesign) => {
            Object.keys(circuitDesign.positionMap).forEach((key) => {
                if (circuitDesign.positionMap[key][Constants.COMPONENT_KEYS.type] === Constants.COMPONENT_TYPES.lag
                && circuitDesign.positionMap[key][Constants.COMPONENT_KEYS.uuid]
                && componentIdToObjectMap[circuitDesign.positionMap[key][Constants.COMPONENT_KEYS.uuid]]) {
                    lagSet.add(componentIdToObjectMap[circuitDesign
                        .positionMap[key][Constants.COMPONENT_KEYS.uuid]].deviceNameInterfaceName);
                }
            });
        });
        return lagSet.size;
    };

    /**
     * This function determines which display value should be shown for the demarcAndCFA object.
     */
    static getDemarcAndCfaDisplayValue = (positionMap, componentName, order, componentIdToObjectMap) => {
        const assignmentIdForNewRevision = HelperFunctions.getAttributeFromComponent(componentIdToObjectMap,
            positionMap, componentName, Constants.ATTRIBUTES.assignmentIdForNewRevision);
        // If the demarcAndCFA has an assignmentIdForNewRevision and we are currently in a change order, then we
        // display the assignmentIdForNewRevision
        if (assignmentIdForNewRevision && order[Constants.ATTRIBUTES.orderType] === Constants.ORDER_TYPES.CHANGE) {
            return assignmentIdForNewRevision;
        }
        return HelperFunctions.getDisplayValueFromComponentName(
            componentIdToObjectMap,
            positionMap,
            componentName
        );
    }

    /**
     * This function gets the display value for a cross connect.
     */
    static getCrossConnectDisplayValue = (positionMap, componentName) => {
        const crossConnect = Object.values(positionMap).find(entry =>
            entry[Constants.COMPONENT_KEYS.name] === componentName);
        if (crossConnect && crossConnect[Constants.COMPONENT_KEYS.uuid]) {
            return crossConnect[Constants.COMPONENT_KEYS.uuid];
        }
        return "";
    };

    /**
     * This function determines which display value should be shown for the providerCircuit object.
     */
    static getProviderCircuitDisplayValue = (positionMap, componentName, order, componentIdToObjectMap) => {
        const providerCircuitNameForNewRevision = HelperFunctions.getAttributeFromComponent(
            componentIdToObjectMap,
            positionMap,
            componentName,
            Constants.ATTRIBUTES.providerCircuitNameForNewRevision
        );
        // If the providerCircuit has an providerCircuitNameForNewRevision and we are currently in a change order,
        // then we display the providerCircuitNameForNewRevision
        if (providerCircuitNameForNewRevision
            && order[Constants.ATTRIBUTES.orderType] === Constants.ORDER_TYPES.CHANGE) {
            return providerCircuitNameForNewRevision;
        }
        return HelperFunctions.getDisplayValueFromComponentName(
            componentIdToObjectMap,
            positionMap,
            componentName
        );
    };

    /**
     * Gets list of number of circuits per bandwidth size
     * Used on SFP Decom OrderAcceptance Keys for Success for example
     * @param circuitDesigns
     * @returns {string} list of number of circuits per bandwidth size
     */
    static getCircuitBandwidthDisplay = (circuitDesigns) => {
        const circuitBandwidths = {};
        circuitDesigns.forEach((circuitDesign) => {
            if (circuitDesign[Constants.ATTRIBUTES.circuitBandwidth] in circuitBandwidths) {
                circuitBandwidths[circuitDesign[Constants.ATTRIBUTES.circuitBandwidth]] += 1;
            } else {
                circuitBandwidths[circuitDesign[Constants.ATTRIBUTES.circuitBandwidth]] = 1;
            }
        });
        return Object.keys(circuitBandwidths).map(key => `${circuitBandwidths[key]} x ${key}G`).join(", ");
    };

    /**
     * Finds the attachment's entityIds in a list based on the attachmentId
     * @param attachments
     * @param attachmentId
     * @returns list of entity ids
     */
    static getExistingEntityIdListForAttachment = (attachments, attachmentId) => {
        const attachment = attachments.find(existingAttachment => existingAttachment.attachmentId === attachmentId);
        return attachment ? HelperFunctions.deepClone(attachment.entityIds) : [];
    };

    /**
     * Figures out what entity ids to remove from an attachment object based on its old and new state as well as what
     * entities have already been modified
     * @param oldIds
     * @param newIds
     * @param modifiedIds
     * @returns list of entity ids
     */
    static findRemovedEntitiesFromAttachment = (oldIds, newIds, modifiedIds) => (oldIds.filter(id =>
        (!newIds.includes(id) && !modifiedIds.includes(id))));

    /**
     * Only has the fields we want in the request.
     * @param attachment
     */
    static createAttachmentRequestObjectFromAttachment = attachment => ({
        attachmentId: attachment.attachmentId,
        fileName: attachment.fileName,
        attachmentType: attachment.attachmentType,
        s3Key: attachment.s3Key,
        contentType: attachment.contentType,
        contentLength: attachment.contentLength,
        auditIdList: attachment.auditIdList,
        entityType: attachment.entityType,
        entityIdList: attachment.entityIdList,
        createdTime: attachment.createdTime,
        createdBy: attachment.createdBy,
        modifiedBy: attachment.modifiedBy,
        modifiedTime: attachment.modifiedTime
    });

    static hasActiveBlocker = blockers => blockers.some(blocker => blocker.status === Constants.STATUS.active);

    /**
     * @Deprecated Does not work with Polaris V3 because we don't provider an onDismiss function
     */
    static generateErrorMessageForFlashbar = (header, content, dismissible = false) => (
        [{
            type: Constants.FLASHBAR_TYPES.error,
            header,
            content,
            dismissible
        }]
    );

    static generateErrorMessageForFlashbarV3 = (header, content, dismissible = true, onDismiss) => (
        [{
            type: Constants.FLASHBAR_TYPES.error,
            header,
            content,
            dismissible,
            onDismiss: () => onDismiss()
        }]
    );

    /**
     * Generates tooltip for stages indicating the next/previous stage(s)
     * @param stageName current stage's name
     * @param stages passed from the order's workflow
     * @returns {string} tooltip
     */
    static generateStageTooltip = (stageName, stages) => {
        let tooltip;
        const stage = stages[stageName];
        if (stage.previousStages) {
            tooltip = "Previous Stage(s): ";
            tooltip += stage.previousStages.map(prevStage =>
                Constants.BACKEND_TO_FRONTEND_STAGE_MAP[prevStage]).join(", ");
        } else {
            tooltip = "Previous Stage(s): Beginning";
        }
        tooltip += "; ";
        if (stage.nextStages) {
            tooltip += "Next Stage(s): ";
            tooltip += stage.nextStages.map(prevStage =>
                Constants.BACKEND_TO_FRONTEND_STAGE_MAP[prevStage]).join(", ");
        } else {
            tooltip += "Next Stage(s): End";
        }
        return tooltip;
    };

    /**
     * Sort a list of circuit items in ascending numerical order
     */
    static sortCircuitDesigns = (circuitDesigns) => {
        if (circuitDesigns.length > 0 && circuitDesigns.some(circuitItem => Object.keys(circuitItem)
            .includes(Constants.ATTRIBUTES.circuitDesignNumber))) {
            circuitDesigns.sort((a, b) => {
                if ((a.circuitDesignNumber && HelperFunctions.parseInt(a.circuitDesignNumber.split("CIRCUIT-")[1])
                    > HelperFunctions.parseInt(b.circuitDesignNumber.split("CIRCUIT-")[1]))) {
                    return 1;
                }
                return -1;
            });
        }
    };

    /**
     * Sort a list of order items in descending numerical order
     */
    static sortOrders = (orders) => {
        if (orders.length > 0) {
            orders.sort((a, b) => {
                if (HelperFunctions.parseInt(a.orderId.split("ORDER-")[1])
                    > HelperFunctions.parseInt(b.orderId.split("ORDER-")[1])) {
                    return -1;
                }
                return 1;
            });
        }
    };

    /**
     * Sorts an array of objects based on two field. This is going to work only on first level fields of the object.
     */
    static sortObjectsTwoFields = (objectArray, fieldA, fieldB) => (
        objectArray.sort((a, b) => {
            // Push null to end of list
            if (!a || !a[fieldA]) { return 1; }
            if (!b || !b[fieldA]) { return -1; }

            // sort on both fields if fieldB is present
            if (a[fieldB] && b[fieldB]) {
                return a[fieldA].localeCompare(b[fieldA]) || a[fieldB].localeCompare(b[fieldB]);
            }

            // Else sort on fieldA if one of them has no fieldB
            return a[fieldA].localeCompare(b[fieldA]);
        })
    );

    /**
     * Sorts an array of objects based on a field. This is going to work only on first level fields of the object.
     */
    static sortObjectsByField = (objectArray, field, desc = false) => (objectArray.sort((a, b) => {
        if (a[field] > b[field]) {
            return desc ? -1 : 1;
        } else if (a[field] < b[field]) {
            return desc ? 1 : -1;
        }
        return 0;
    }));

    /**
     * Sorts an array of contact objects in case-insenstive way based on a field.
     */
    static sortContactsByField = (objectArray, field, desc = false) => (objectArray.sort((a, b) => {
        // Push null to end of list
        if (!a || !a[field]) { return 1; }
        if (!b || !b[field]) { return -1; }

        // case-insensitive sort
        return desc ? -1 * a[field].localeCompare(b[field]) : a[field].localeCompare(b[field]);
    }));

    /**
     * Comparator for sorting ids that have numbers in it. For example, Sorting ['Order-9', 'Order-10'] based on the
     * number value correctly instead of alphabetically. Subtracting works because sorting really just cares about
     * a positive or negative value (since we are working with numbers here).
     */
    static sortIdsNumerically = (a, b, fieldName) => {
        // We need to account for the cases where the field is undefined in a, b, or both
        if (!a[fieldName] && !!b[fieldName]) return -1;
        if (!!a[fieldName] && !b[fieldName]) return 1;
        if (!a[fieldName] && !b[fieldName]) return 0;
        return a[fieldName].match(/\d+$/)[0] - b[fieldName].match(/\d+$/)[0];
    }

    static sortNumberStrings = (a, b, fieldName) => (
        HelperFunctions.parseInt(a[fieldName]) - HelperFunctions.parseInt(b[fieldName])
    );

    static sortByListLength = (a, b, fieldName) => (
        a[fieldName].length - b[fieldName].length
    );

    static capitalize = word => (word.charAt(0).toUpperCase() + word.substr(1));

    static isComponentFremontCircuit = (component) => {
        if (!component
            || !component[Constants.COMPONENT_KEYS.componentGroup]
            || !component[Constants.COMPONENT_KEYS.type]) {
            return false;
        }
        return Constants.COMPONENT_NAMES.fremontCircuit === component[Constants.COMPONENT_KEYS.componentGroup]
            && Constants.COMPONENT_TYPES.customComponent === component[Constants.COMPONENT_KEYS.type];
    }

    /**
     * Creates groupings for select component options
     * @param optionList list of options (ungrouped)
     * @param field which we are grouping these options by, option must contain this field
     * @returns {[]} grouped options
     */
    static groupOptions = (optionList, field) => {
        const groupedOptions = [];
        optionList.forEach((option) => {
            const groupIndex = groupedOptions.findIndex(group => group.label === option[field]);
            if (groupIndex === -1) {
                groupedOptions.push({
                    label: option[field],
                    options: [option]
                });
            } else {
                groupedOptions[groupIndex].options.push(option);
            }
        });
        HelperFunctions.sortObjectsByField(groupedOptions, "label");
        return groupedOptions;
    };

    /**
     * Fetches circuits and sites for nodeItems so they can be displayed in the NodeTable
     * @param nodes
     * @param auth
     */
    static fetchNodeItems = async (nodes, auth) => {
        const fremontBackendClient = new FremontBackendClient();
        const nodeItems = HelperFunctions.deepClone(nodes);
        // Get circuits
        const circuitDesignIds = uniq(nodeItems.flatMap(node => node.circuitDesignIdList));
        const circuitDesignResponse = await fremontBackendClient.getBatch(
            Constants.BATCH_ENTITIES.CIRCUIT_DESIGN, circuitDesignIds, auth
        );

        nodeItems.forEach(node =>
            Object.assign(node, {
                circuitDesignList: circuitDesignResponse.circuitDesigns
                    .filter(circuitDesign => node.circuitDesignIdList.includes(circuitDesign.circuitDesignId))
            }));

        const siteIds = nodeItems.map(node => node.siteId);
        const siteResponse = await fremontBackendClient.getBatch(Constants.BATCH_ENTITIES.SITE, siteIds, auth);

        nodeItems.forEach(node =>
            Object.assign(node, {
                site: siteResponse.sites.find(site => node.siteId === site.siteId)
            }));
        return nodeItems;
    };

    /**
     * This function returns true if a user is currently being mimicked, false otherwise.
     */
    static isMimickingUser = auth => !!auth.mimickedUserId && auth.mimickedUserId !== auth.userId;

    /**
     * This helper functions creates selected options depending on ttId
     */
    static createTTStatusSelectedOptions = (ttId) => {
        const matchesTTRegex = new RegExp("^(https://|^)(t.corp.amazon.com/|tt.amazon.com/).*").test(ttId);
        const matchesSIMRegex = new RegExp("^(https://|^)(issues.amazon.com/|sim.amazon.com/).*").test(ttId);

        if (matchesTTRegex) {
            return HelperFunctions.createSelectedOptionsWithNone(Constants.TT_STATUS_OPTIONS);
        } else if (matchesSIMRegex) {
            return HelperFunctions.createSelectedOptionsWithNone(Constants.SIM_STATUS_OPTIONS);
        }
        return [];
    }

    // Handles converting component name strings to lowercase
    static componentNameToLowerCase = (componentNameString) => {
        if (!componentNameString) {
            return componentNameString;
        }
        // If the componentNameString contains "_NA", the dummy NCIS component value (which is always capitalized),
        // then we want to convert everything to lowercase except for the "_NA".
        if (componentNameString.slice(-3) === Constants.NCIS_DUMMY_COMPONENT_VALUE) {
            return componentNameString.substring(0, componentNameString.length - 3).toLowerCase()
                + Constants.NCIS_DUMMY_COMPONENT_VALUE;
        }
        return componentNameString.toLowerCase();
    };

    static createNewApiObjectsCircuitWrapperForStage = (originalObjects, updatedCircuitDesignObjects) => {
        // Only stage that modifies the positionMap directly is cabling with edits to cross connect, but we never
        // use this wrapper function there.
        const circuitsToUpdate = HelperFunctions.createNewApiObjects(originalObjects,
            updatedCircuitDesignObjects,
            Constants.ATTRIBUTES.circuitDesignId,
            Constants.KEEP_KEYS.CIRCUIT,
            Constants.IGNORE_KEYS.CIRCUIT);

        const filtered = circuitsToUpdate.filter(circuit => Object.keys(circuit).length > 1);
        return filtered;
    }

    static generateCommonSortableColumns = () => [
        {
            id: Constants.TABLE_IDS.circuitDesignLink,
            comparator: (circuit1, circuit2) => (HelperFunctions.sortIdsNumerically(circuit1, circuit2,
                "circuitDesignNumber"))
        }
    ]

    /**
     * @deprecated Use createV2Objects() instead.
     * This function generically creates objects for the new Fremont API. If an object is unchanged, it will not be
     * returned from this function.
     * @param originalObjects List of original objects
     * @param updatedObjects List of updated objects
     * @param identifier Identifier to help us find an object in both lists
     * @param keysToKeep Keys to keep which (field always required in a request such as the id)
     * @returns {[]} List of stripped objects that need to be submitted to Fremont
     */
    static createNewApiObjects = (originalObjects, updatedObjects, identifier, keysToKeep = [], keysToIgnore = []) => {
        const objectsForRequest = [];
        originalObjects.forEach((originalObject) => {
            const [updatedObject] = updatedObjects.filter(object => object[identifier] === originalObject[identifier]);
            const changedKeys = HelperFunctions.findDiff(originalObject, updatedObject, keysToKeep, keysToIgnore);
            // Since we have certain keys we always want, we need to check if changed keys length is more than
            // keysToKeep length (so we don't assume an object has changed just because we're keeping a key)
            if (changedKeys.length > keysToKeep.length) {
                const requestObject = HelperFunctions.keepKeys(updatedObject, changedKeys);

                // All string fields need to be converted to an empty string (if they are null by any chance) since
                // that means we've cleared it out. All fields that are objects or lists should never be undefined,
                // so the assumption is that if the field fails the null check, its a string variable
                Object.keys(requestObject).forEach((key) => {
                    if (requestObject[key] === null) {
                        requestObject[key] = "";
                    }
                });
                objectsForRequest.push(requestObject);
            }
        });

        // Use this to test how the request objects look
        // console.log(objectsForRequest);

        return objectsForRequest;
    };

    /**
     * This function generically creates objects for the new Fremont API. If an object is unchanged, it will not be
     * returned from this function.
     * @param updatedObjects Updated state objects
     * @param originalObjects Original prop objects (or objects fetched when the component mounted)
     * @param objectName Name of a fremont object
     * @param identifiers Keys used to match an updated object to an original one
     * @returns {[]} List of stripped objects that need to be submitted to Fremont
     */
    static createV2Objects = (updatedObjects, originalObjects = [], objectName, identifiers) => {
        const objectsForRequest = [];
        const keysToKeep = Object.keys(Constants.FREMONT_OBJECTS[objectName]);
        updatedObjects.forEach((updatedObject) => {
            // Try to find the matching original object through one of the identifiers, if updatedObject doesn't
            // have any identifiers or doesn't match any original object, original object is blank
            let originalObject = {};
            if (Object.keys(HelperFunctions.keepKeys(updatedObject, identifiers)).length > 0) {
                originalObject = originalObjects.find(
                    ogObject => identifiers.every(id => isEqual(updatedObject[id], ogObject[id]))
                ) || {};
            }

            // Find if any values have changed
            let changedKeys = union(Object.keys(updatedObject), Object.keys(originalObject))
                .filter(key => keysToKeep.includes(key) && !isEqual(updatedObject[key], originalObject[key]));

            if (changedKeys.length > 0) {
                // Add identifiers
                changedKeys = union(changedKeys, identifiers);
                const requestObject = HelperFunctions.keepKeys(updatedObject, changedKeys);

                // All string fields need to be converted to an empty string (if they are null by any chance) since
                // that means we've cleared it out. All fields that are objects or lists should never be undefined,
                // so the assumption is that if the field fails the null check, its a string variable
                Object.keys(requestObject).forEach((key) => {
                    if (requestObject[key] === null) {
                        requestObject[key] = "";
                    }
                });
                objectsForRequest.push(requestObject);
            }
        });

        return objectsForRequest;
    };

    /**
     * Finding all the properties that are different for a given object. We're using Lodash's isEquals() for comparison.
     * We need to check the keys in each object since there may be a key in one but not the other
     * @param updated Updated object
     * @param original Original object
     * @param keysToKeep Keys that should always be included (such as the identifier)
     * @param keysToIgnore Keys that should never be included (state augmenting fields)
     * @returns {[]} List of keys that are different in both objects.
     */
    static findDiff = (updated, original = {}, keysToKeep = [], keysToIgnore = []) => {
        // Check what keys are different in obj1 compared to obj2. We need to check from both sides since empty string
        // values won't be there in our original object. If a key is one that we should ignore, then we want to not
        // include that in our request. If the key is present in our keys to keep or if the key has changed,
        // we add it to our changedKeys
        let changedKeys = [];

        // Sometimes we have fields that we add in our frontend to augment the state, so we can remove those from here
        // We also need to remove keys that are in `keysToRemove`
        const keysToRemove = keysToIgnore.concat(Constants.DENY_LISTED_KEYS);
        union(Object.keys(updated), Object.keys(original))
            .filter(key => !keysToRemove.includes(key))
            .forEach(key => !isEqual(updated[key], original[key]) && changedKeys.push(key));

        // Add all the keys that are needed always (usually something like an identifier or an id)
        changedKeys = changedKeys.concat(keysToKeep);

        return HelperFunctions.unique(changedKeys);
    };

    /**
     * Strips the objects of all but the keys specified.
     * @param object Object to update
     * @param keys Keys to keep
     * @returns {any} New object with just the keys to keep
     */
    static keepKeys = (object, keys = []) => {
        const newObject = HelperFunctions.deepClone(object);
        Object.keys(newObject)
            .forEach(key => !keys.includes(key) && delete newObject[key]);
        return newObject;
    };
    static trimTrailingAndLeadingWhitespace = (input) => {
        if (Array.isArray(input)) {
            input.forEach((item) => {
                HelperFunctions.trimTrailingAndLeadingWhitespace(item);
            });
        } else if (input && typeof input === "object") {
            Object.keys(input).forEach((key) => {
                if (typeof input[key] === "object") {
                    HelperFunctions.trimTrailingAndLeadingWhitespace(input[key]);
                } else if (typeof input[key] === "string") {
                    Object.assign(input, { [key]: input[key].trim() });
                }
            });
        }
        return input;
    };


    static getAdvancedSearchCookie = () => {
        const cookieString = document.cookie.indexOf("advancedSearch=");

        // on the first load, advancedSearch will not exist as a cookie
        if (cookieString === -1) {
            document.cookie = "advancedSearch=false";
            return false;
        }

        // otherwise we parse the document.cookie and see if it is equal to true
        const stringValue = document.cookie.split("advancedSearch=").pop().split(";")[0];
        return stringValue === "true";
    }

    static setAdvancedSearchCookie = (value) => {
        document.cookie = `advancedSearch=${value}`;
    }


    /**
     * Dedupes a list of duplicate elements.
     * @param list
     * @returns {unknown[]} Unique list of elements
     */
    static unique = list => Array.from(new Set(list));

    static isLocalHost = () => window.location.host.includes("localhost");

    static isBetaOrGamma = () => window.location.host.includes("beta.")
        || window.location.host.includes("beta-cn.")
        || window.location.host.includes("gamma.")
        || window.location.host.includes("gamma-cn.");

    // isProd should return true for Prod as well Gamma (This is for testing in Gamma Environment when leveraging
    // on feature flag)
    static isProd = () => window.location.host.includes("prod") || window.location.host.includes("gamma");

    static isGamma = () => window.location.host.includes("gamma");

    static isCNStack = () => window.location.host.includes("-cn");

    static isDevelopmentStack = () => window.location.host.includes(DevEnvironment.YOUR_ALIAS);

    static userInPosixGroup = (user, posixGroup) => user.permissions[posixGroup];

    static hideIdsInAuditMessages = (auditMessage, user) => {
        if (!HelperFunctions.isLocalHost() && !HelperFunctions.isDevelopmentStack()
            && !HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.NEST)) {
            return auditMessage.replace(Constants.REGEX_PATTERNS.uuidInAudit, "");
        }
        return auditMessage;
    }

    /**
     * Checks if the input object is empty: {} or undefined.
     * @param object object to test
     * @returns {boolean}
     */
    static isEmpty = object => !object || Object.keys(object).length === 0;

    static getAuthConfigEnvironments = () => {
        const dev = {
            AppWebDomain: `fremont-${DevEnvironment.YOUR_ALIAS}-test-userpool.auth.us-west-2.amazoncognito.com`, // without https://
            ClientId: `${DevEnvironment.YOUR_CLIENT_ID}`, // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://${DevEnvironment.YOUR_ALIAS}.test.fremont.networking.aws.a2z.com`,   // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://${DevEnvironment.YOUR_ALIAS}.test.fremont.networking.aws.a2z.com`,  // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: `${DevEnvironment.YOUR_USER_POOL_ID}`   // the user pool from Cognito "General settings" page
        };

        const lighthouseDev = {
            ...dev,
            RedirectUriSignIn: `https://${DevEnvironment.YOUR_ALIAS}.test.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://${DevEnvironment.YOUR_ALIAS}.test.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        const prod = {
            AppWebDomain: "fremont-prod-userpool.auth.us-east-1.amazoncognito.com", // without https://
            ClientId: "79069d1m0pfitij6gh4d84olh1", // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://prod.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://prod.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: "us-east-1_FDwmktIzf"   // the user pool from Cognito "General settings" page
        };

        const lighthouseProd = {
            ...prod,
            RedirectUriSignIn: `https://prod.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://prod.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        const prodcn = {
            AppWebDomain: "fremont-prod-cn-userpool.auth.us-east-1.amazoncognito.com", // without https://
            ClientId: "2qcf1m8fkgfptlis8dloipofhk", // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://prod-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://prod-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: "us-east-1_hq6pjie20"   // the user pool from Cognito "General settings" page
        };

        const lighthouseProdcn = {
            ...prodcn,
            RedirectUriSignIn: `https://prod-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://prod-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        const gamma = {
            AppWebDomain: "fremont-gamma-userpool.auth.us-west-2.amazoncognito.com", // without https://
            ClientId: "11kufkoqmi04dnrlpdm705uk4l", // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://gamma.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://gamma.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: "us-west-2_OXxQHaWhm"   // the user pool from Cognito "General settings" page
        };

        const lighthouseGamma = {
            ...gamma,
            RedirectUriSignIn: `https://gamma.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://gamma.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        const gammacn = {
            AppWebDomain: "fremont-gamma-cn-userpool.auth.us-west-2.amazoncognito.com", // without https://
            ClientId: "7f1vccfpivdllmtbt3drga3urp", // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://gamma-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://gamma-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: "us-west-2_Ys8TJyUQI"   // the user pool from Cognito "General settings" page
        };

        const lighthouseGammacn = {
            ...gammacn,
            RedirectUriSignIn: `https://gamma-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://gamma-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        const beta = {
            AppWebDomain: "fremont-beta-userpool.auth.us-west-2.amazoncognito.com", // without https://
            ClientId: "6bnb10brf3gma4dtrs6aem90v6", // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://beta.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://beta.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: "us-west-2_2cgvBlcKb"   // the user pool from Cognito "General settings" page
        };

        const lighthouseBeta = {
            ...beta,
            RedirectUriSignIn: `https://beta.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://beta.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        const betacn = {
            AppWebDomain: "fremont-beta-cn-userpool.auth.us-west-2.amazoncognito.com", // without https://
            ClientId: "77d02cj4dagrld6i2ltij3s1ob", // the client ID from Cognito "General settings > App clients" page
            RedirectUriSignIn: `https://beta-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            RedirectUriSignOut: `https://beta-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`, // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            TokenScopesArray: ["openid", "email", "profile"],
            UserPoolId: "us-west-2_XD0n3mRTq"   // the user pool from Cognito "General settings" page
        };

        const lighthouseBetacn = {
            ...betacn,
            RedirectUriSignIn: `https://beta-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`,
            RedirectUriSignOut: `https://beta-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`
        };

        return {
            // Lighthouse
            [`prod.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseProd,
            [`prod-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseProdcn,
            [`gamma.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseGamma,
            [`gamma-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseGammacn,
            [`beta.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseBeta,
            [`beta-cn.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseBetacn,
            [`${DevEnvironment.YOUR_ALIAS}.test.${HelperFunctions.LIGHTHOUSE_ROOT_DOMAIN}`]: lighthouseDev,

            // Fremont
            [`prod.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseProd,
            [`prod-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseProdcn,
            [`gamma.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseGamma,
            [`gamma-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseGammacn,
            [`beta.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseBeta,
            [`beta-cn.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseBetacn,
            [`${DevEnvironment.YOUR_ALIAS}.test.${HelperFunctions.FREMONT_ROOT_DOMAIN}`]: lighthouseDev,

            /**
             * While developing locally, you may want to connect to your own stack, beta, or gamma.
             * Uncomment the respective config.
             *
             * Be sure to also update FremontBackendClient to determine the env config
             */
            "localhost:8080": {
                AppWebDomain: `${DevEnvironment.APP_WEB_DOMAIN}`,
                ClientId: `${DevEnvironment.YOUR_CLIENT_ID}`, // client ID from Cognito "General settings > App clients"
                RedirectUriSignIn: "http://localhost:8080",   // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
                RedirectUriSignOut: "http://localhost:8080",  // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
                TokenScopesArray: ["openid", "email", "profile"],
                UserPoolId: `${DevEnvironment.YOUR_USER_POOL_ID}`   // the user pool from Cognito "General settings" page
            }
            // "localhost:8080": {
            //     AppWebDomain: "fremont-beta-userpool.auth.us-west-2.amazoncognito.com", // without https://
            //     ClientId: "6bnb10brf3gma4dtrs6aem90v6", // client ID from Cognito "General settings > App clients"
            //     RedirectUriSignIn: "http://localhost:8080",   // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            //     RedirectUriSignOut: "http://localhost:8080",  // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            //     TokenScopesArray: ["openid", "email", "profile"],
            //     UserPoolId: "us-west-2_2cgvBlcKb"   // the user pool from Cognito "General settings" page
            // }
            // "localhost:8080": {
            //     AppWebDomain: "fremont-gamma-userpool.auth.us-west-2.amazoncognito.com", // without https://
            //     ClientId: "11kufkoqmi04dnrlpdm705uk4l", // client ID from Cognito "General settings > App clients"
            //     RedirectUriSignIn: "http://localhost:8080",   // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page. URL can be changed once we have our own domain
            //     RedirectUriSignOut: "http://localhost:8080",  // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
            //     TokenScopesArray: ["openid", "email", "profile"],
            //     UserPoolId: "us-west-2_OXxQHaWhm"   // the user pool from Cognito "General settings" page
            // }
        };
    };

    static getCloudwatchRumConfig = () => {
        let environmentKey = Constants.STAGES.test;
        if (HelperFunctions.isGamma()) {
            environmentKey = Constants.STAGES.gamma;
        } else if (HelperFunctions.isProd()) {
            environmentKey = Constants.STAGES.prod;
        }

        return Constants.CLOUDWATCH_RUM_CONFIG[environmentKey];
    }

    static addEmptyOpticalMetaMapToOrder = (order) => {
        const clonedOrder = HelperFunctions.deepClone(order);
        clonedOrder.opticalMetaMap = {};
        return clonedOrder;
    }

    static alphanumericSortList = (list) => {
        const sortedList = list.sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }));
        return sortedList;
    }

    static isValidOpticalDesignString = (opticalDesignString) => {
        if (!opticalDesignString) {
            return true;
        }
        const spans = opticalDesignString.split(",");
        for (let i = 0; i < spans.length; i += 1) {
            if (!spans[i].trim().match(Constants.REGEX_PATTERNS.opticalDesignString)) {
                return false;
            }
        }
        return true;
    }

    static sortTopology = (topology, startingSiteId, endingSiteId, orderMap) => {
        const topologyList = Object.values(topology).flat();
        // topology does not need sorting if <2 row
        if (topologyList.length < 2) {
            return topology;
        }
        const siteToTopologyMap = new Map();

        // Create a mapping from each site to list of topologyObjects
        topologyList.forEach((topologyObject) => {
            const siteAId = topologyObject.siteAId ? topologyObject.siteAId : orderMap[topologyObject.orderId].siteAId;
            const siteZId = topologyObject.siteZId ? topologyObject.siteZId : orderMap[topologyObject.orderId].siteZId;
            if (!siteToTopologyMap.has(siteAId)) {
                siteToTopologyMap.set(siteAId, []);
            }
            if (!siteToTopologyMap.has(siteZId)) {
                siteToTopologyMap.set(siteZId, []);
            }
            siteToTopologyMap.get(siteAId).push(topologyObject);
            siteToTopologyMap.get(siteZId).push(topologyObject);
        });

        const sortedTopologyMap = {};
        let position = 0;
        let currentSite = startingSiteId;
        const visitedOrders = new Set();
        let foundNext = false;

        // Traverse from startSite to endSite (pathOrder siteA and siteZ respectively)
        while (currentSite !== endingSiteId) {
            const candidates = siteToTopologyMap.get(currentSite) || [];
            foundNext = false;
            position += 1;
            sortedTopologyMap[position] = [];
            let nextCurrentSite;

            // we are assuming the candidates would only include the visitedOrders or parallel orders
            // e.g. there cannot be a span from a->b, a->b and b->c but not a->c or b->c within same path order
            for (let index = 0; index < candidates.length; index += 1) {
                const topologyObject = candidates[index];
                if (!visitedOrders.has(topologyObject.orderId)) {
                    sortedTopologyMap[position].push(topologyObject);
                    visitedOrders.add(topologyObject.orderId);
                    const siteAId = topologyObject.siteAId ? topologyObject.siteAId
                        : orderMap[topologyObject.orderId].siteAId;
                    const siteZId = topologyObject.siteZId ? topologyObject.siteZId
                        : orderMap[topologyObject.orderId].siteZId;
                    nextCurrentSite = (currentSite === siteAId) ? siteZId : siteAId;
                    foundNext = true;
                }
            }

            // if we cannot build a sortedTopologyMap, keep original topologyMap as it is
            if (!foundNext) {
                console.log(`Path is not continuous: cannot find next order from site ${currentSite}`);
                return topology;
            }
            currentSite = nextCurrentSite;
        }

        return sortedTopologyMap;
    }

    static getProviderCircuitName = (siteAName, siteZName, spanName) => `${siteAName}-${siteZName}_${spanName}`.toUpperCase();

    static normalizeSiteCode = siteCode => `${siteCode.slice(0, 3)}${siteCode.slice(3).padStart(3, "0")}`;

    static isOrderOpticalInstallPathOrder = order =>
        HelperFunctions.isOrderPathOrder(order) && HelperFunctions.isProviderAmazonInternal(order.providerName)
        && Constants.ORDER_TYPES.INSTALL === order.orderType;
}