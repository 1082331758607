import React, { useState, useEffect } from "react";
import {
    Box,
    SpaceBetween,
    Table,
    TextFilter,
    Header,
    CollectionPreferences,
    Pagination
} from "@amzn/awsui-components-react/polaris";

import { useCollection } from "@amzn/awsui-collection-hooks";
import Constants from "../../../mango/js/utils/Constants";
import ConstantsLightHouse from "../utils/Constants";
import HelperFunctions from "../../../mango/js/common/HelperFunctions";
import { LinkServiceButton, LinkServiceMultiSelect }
from "../../../mango/js/components/CommonComponents";

export const Preferences = ({
    preferences,
    setPreferences,
    contentDisplayPreference,
    entity,
    disabled
}) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => {
            setPreferences(detail);
            HelperFunctions.setHiddenColumnsCookie(entity,
                (detail.contentDisplay || []).filter(c => !c.visible).map(c => c.id).join(","));
        }}
        pageSizePreference={{
            title: "Page size",
            options: [
                { value: 10, label: "10 Items" },
                { value: 25, label: "25 Items" },
                { value: 50, label: "50 Items" },
                { value: 100, label: "100 Items" },
                { value: 250, label: "250 Items" }
            ]
        }}
        wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines"
        }}
        contentDisplayPreference={contentDisplayPreference}
        stickyColumnsPreference={{
            firstColumns: {
                title: "Stick first column(s)",
                description:
                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                options: [
                    { label: "None", value: 0 },
                    { label: "First column", value: 1 },
                    { label: "First two columns", value: 2 },
                    { label: "First three columns", value: 3 }
                ]
            },
            lastColumns: {
                title: "Stick last column",
                description:
                    "Keep the last column visible while horizontally scrolling the table content.",
                options: [
                    { label: "None", value: 0 },
                    { label: "Last column", value: 1 }
                ]
            }
        }}
    />
);

const pageLabel = pageNumber => `Page ${pageNumber} of all pages`;

export default function IspCutsheetTable(props) {
    const [preferences, setPreferences] = useState({
        pageSize: 100,
        wrapLines: true,
        contentDisplay: [],
        stickyColumns: { first: 1, last: 0 }
    });

    // // selected Itesm for the checkbox on the rows of the table
    const [
        selectedItems,
        setSelectedItems
    ] = React.useState([]);

    // // Determine the identifier property based on cutsheetType
    const [identifierProperty, setIdentifierProperty] = useState(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.CLIENT);

    useEffect(() => {
        switch (props.cutsheetType) {
        case ConstantsLightHouse.ISP_CUTSHEET_TYPE.LINE:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.LINE);
            break;

        case ConstantsLightHouse.ISP_CUTSHEET_TYPE.CLIENT:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.CLIENT);
            break;

        case ConstantsLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.INTERNAL_CABLING);
            break;
        default:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.CLIENT);
        }
    });

    const contentDisplayPreference = {
        title: "Column preferences",
        options: props.updatedColumnDefinitions.map(c => ({
            id: c.id,
            label: c.header
        }))
    };

    const [
        filteringText,
        setFilteringText
    ] = useState("");

    const { items, collectionProps, paginationProps } = useCollection(
        props.items,
        {
            filtering: {
                filteringFunction: item => Object.keys(item)
                    .some(key => item[key] && item[key].includes(filteringText))
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {},
            stickyColumns: preferences.stickyColumns
        }
    );

    // selected options for the LIU multiselect
    const [
        selectedOptions,
        setSelectedOptions
    ] = React.useState([]);

    const getIdPortAValue = column => column.id_port_a;

    useEffect(() => {
        // Loop through IspCutsheetTableData.hopOptionsDropDown and check if any row in props.items
        // has the corresponding key/value
        const requiredSelectedOptions = props.hopOptionsDropDown
            .filter((column) => {
                const idPortAValue = getIdPortAValue(column);
                return props.items.some(item => item[idPortAValue]);
            });

        // Combine requiredSelectedOptions with existing selectedOptions
        const updatedSelectedOptions = [...selectedOptions, ...requiredSelectedOptions];

        // Update the state with the modified selected options
        setSelectedOptions(updatedSelectedOptions);
    }, [props.items, props.hopOptionsDropDown]); // Run the effect whenever props.items changes

    useEffect(() => {
        // Update contentDisplay when updatedColumnDefinitions change
        const columnsString = HelperFunctions.getHiddenColumnsCookie(props.cutsheetType);
        const hiddenColumns = columnsString ? columnsString.split(",") : [];

        const newContentDisplay = props.updatedColumnDefinitions.map(c => ({
            id: c.id,
            visible: !hiddenColumns.includes(c.id)
        }));

        setPreferences(prevPreferences => ({
            ...prevPreferences,
            contentDisplay: newContentDisplay
        }));
    }, [props.updatedColumnDefinitions]);

    return (
        <div>
            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_BUTTON_PADDING}>
                <Table
                    {...collectionProps}
                    columnDefinitions={props.updatedColumnDefinitions}
                    columnDisplay={preferences.contentDisplay}
                    items={items}
                    selectionType="multi"
                    onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                    selectedItems={selectedItems}
                    allowFreeTextFiltering
                    loading={props.isSearchInProgress}
                    loadingText="Loading resources"
                    stickyHeader
                    trackBy={identifierProperty}
                    stickyColumns={preferences.stickyColumns}
                    pagination={
                        <Pagination
                            {...paginationProps}
                            // openEnd={canFetchMore(props.nextToken)}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",
                                pageLabel
                            }}
                        />}
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                                <b>No resources</b>
                            </SpaceBetween>
                        </Box>
                    }
                    filter={
                        <TextFilter
                            filteringText={filteringText}
                            filteringPlaceholder="Find instances"
                            filteringAriaLabel="Filter instances"
                            onChange={({ detail }) =>
                                setFilteringText(detail.filteringText)
                            }
                        />
                    }
                    contentDensity="comfortable"
                    submitEdit={async (item, column, newValue) => {
                        // if the column being submitted is a Patch Panel A column
                        // then call the backend to search for the patchpanels
                        // this getIPPMInformation will make a network call
                        if (props.hopOptionsDropDown.some(hop => hop.id_pp_a === column.id)) {
                            if (newValue.trim()) {
                                await props.getIPPMInformation(newValue);
                            }
                            props.handlePatchPanelAInputChangeForLiu(item, column, newValue.trim(), selectedItems);
                        } else if (props.hopOptionsDropDown.some(hop => hop.id_port_a === column.id
                            || hop.id_port_z === column.id)) {
                            // for ISP Port input we need a special handler which does a special massupdate
                            props.handlePortAInputChangeForLiu(item, column, newValue.trim(), selectedItems);
                        } else if (column.id === ConstantsLightHouse.ISP_ATTRIBUTES.a_osp_panel_location) {
                            props.handleAOspPanelLocationChange(item, column, newValue.trim(), selectedItems);
                        } else if (column.id === ConstantsLightHouse.ISP_ATTRIBUTES.a_osp_panel_ports
                            || column.id === ConstantsLightHouse.ISP_ATTRIBUTES.z_osp_panel_ports) {
                            // mass update may need to be used for updating osp ports
                            props.handleOspPortUpdates(item, column, newValue.trim(), selectedItems);
                        } else {
                            // call backend to update individual attributes
                            props.handleOtherFieldUpdates(item, column, newValue.trim(), selectedItems);
                        }
                    }}
                    header={
                        <Header
                            variant="h4"
                            actions={
                                <SpaceBetween
                                    direction="horizontal"
                                    size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}
                                >
                                    <LinkServiceMultiSelect
                                        selectedOptions={selectedOptions}
                                        onChange={({ detail }) => {
                                            // Identify required columns
                                            const requiredColumns = props.hopOptionsDropDown.filter(
                                                (column) => {
                                                    const idPortAValue = getIdPortAValue(column);
                                                    return props.items.some(item => item[idPortAValue]);
                                                }
                                            );

                                            // Combine the required columns with the newly selected options
                                            const updatedSelectedOptions = Array.from(
                                                new Set([...requiredColumns, ...detail.selectedOptions])
                                            );
                                            setSelectedOptions(updatedSelectedOptions);
                                            props.addRemoveLiuColumns(updatedSelectedOptions);
                                        }}
                                        options={props.hopOptionsDropDown}
                                        hideTokens
                                        placeholder="Choose options"
                                        tokenLimit={2}
                                    />
                                    <LinkServiceButton
                                        variant="primary"
                                        loading={props.submissionInProgress}
                                        disabled={props.submissionInProgress}
                                        onClick={props.submitUpdates}
                                    >
                                    Submit Updates
                                    </LinkServiceButton>
                                    <LinkServiceButton
                                        variant="primary"
                                        iconName="download"
                                        iconAlign="left"
                                        onClick={() =>
                                            HelperFunctions.downloadTableAsCutsheet(
                                                props.items,
                                                HelperFunctions.getIspTableDownloadableColumnDefinitions(
                                                    preferences.contentDisplay, props.updatedColumnDefinitions
                                                )
                                            )}
                                        loading={false}
                                        disabled={false}
                                    >
                                            Download All Records
                                    </LinkServiceButton>
                                </SpaceBetween>
                            }
                        >
                            {props.header}
                        </Header>
                    }
                    preferences={
                        <Preferences
                            preferences={preferences}
                            setPreferences={setPreferences}
                            contentDisplayPreference={contentDisplayPreference}
                            entity={props.cutsheetType}
                        />}
                />
            </SpaceBetween>
        </div>);
}