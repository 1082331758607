import React from "react";
import { Button, Checkbox, Input, Modal, Select } from "@amzn/awsui-components-react";

/**
 * This method exists as a wrapper method on top of the polaris <Button> method to pass the id parameter.
 */
export const LightHouseButton = props => (
    <Button
        {...props}
        onClick={evt => props.onClick({ evt, target: { id: props.id } })}
    >
        {props.children}
    </Button>
);

/**
 * This method exists as a wrapper method on top of the polaris <Input> method to pass the id parameter.
 */
export const LightHouseInput = props => (
    <Input
        {...props}
        onChange={evt => props.onChange({ ...evt, target: { id: props.id } })}
    >
        {props.children}
    </Input>
);

/**
 * This method exists as a wrapper method on top of the polaris <Modal> method
 */
export const LightHouseModal = props => (
    <Modal
        size={props.size}
        visible={props.isModalVisible}
        onDismiss={props.onDismiss}
        closeAriaLabel="Close modal"
        header={props.header}
        footer={props.footer}
    >
        {props.children}
    </Modal>
);

export const LightHouseCheckbox = props => (
    <Checkbox
        {...props}
        onChange={evt => props.onChange({ ...evt, target: { id: props.id } })}
    >
        {props.children}
    </Checkbox>
);

/**
 * This method exists as a wrapper method on top of the polaris <Select> method to pass the id parameter.
 */
export const LightHouseSelect = props => (
    <Select
        {...props}
        onChange={({ detail }) => props.onChange({ ...detail, target: { id: props.id } })}
    />
);